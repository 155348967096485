import { Box, Button, Dialog, Link, Typography } from '@mui/material';
import StoreContext from 'contexts/storeContext';
import { useContext, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { makeStyles } from 'tss-react/mui';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

type Props = {
  logout: () => void;
  acceptTermsAndConditions: () => void;
};

const useStyles = makeStyles()((theme) => ({
  document: {
    width: '100%',
  },
  page: {
    width: '100%',
    minWidth: 'unset !important',
    minHeight: 'unset !important',
    '& canvas': {
      width: '100% !important',
      height: 'unset !important',
    },
  },
}));

export default function TermsAndConditionsDialog({ logout, acceptTermsAndConditions }: Props) {
  const { classes } = useStyles();
  const { store } = useContext(StoreContext);
  const [numPages, setNumPages] = useState(0);

  const onLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Dialog open sx={{ '& .MuiPaper-root': { height: '100%' } }}>
      <Box padding={2} display='flex' flexDirection='column' flex={1} height='100%'>
        <Typography variant='h6' sx={{ mb: 1 }}>
          Términos y condiciones
        </Typography>
        <Typography variant='caption'>
          ¡Hemos actualizado nuestros términos y condiciones! Lee y acéptalos nuevamente para continuar haciendo uso de la plataforma.
        </Typography>
        <Typography variant='caption' sx={{ mb: 1 }}>
          En caso de no poder visualizarlo correctamente, puedes descargar el documento{' '}
          <Link href={store.terms_and_conditions_to_approve.file} sx={{ fontWeight: 600 }} target='_blank'>
            aquí
          </Link>
          .
        </Typography>
        <Box display='flex' flexDirection='column' alignItems='center' flex={1} overflow='scroll' width='100%'>
          <Document
            file={store.terms_and_conditions_to_approve.file}
            onLoadSuccess={onLoad}
            className={classes.document}
            loading='Cargando términos y condiciones...'
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                className={classes.page}
                loading={<></>}
              />
            ))}
          </Document>
        </Box>
        <Box display='flex' justifyContent='space-between' sx={{ mt: 1 }} flexWrap='wrap'>
          <Button sx={{ mx: 1, mt: 1 }} onClick={logout}>
            Cerrar sesión
          </Button>
          <Button sx={{ mx: 1, mt: 1 }} variant='contained' onClick={acceptTermsAndConditions}>
            He leído y acepto los términos y condiciones
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
