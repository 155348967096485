import { Box, Grow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f2c132',
    color: 'white',
    borderRadius: 5,
    padding: '10px 15px',
    maxWidth: '80%',
    left: 10,
    top: '100%',
    zIndex: 1,
  },
  triangle: {
    position: 'absolute',
    top: -10,
    left: 20,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #f2c132',
  },
  text: {
    textAlign: 'center',
    fontWeight: 500,
  },
}));

type Props = {
  text: string;
  delay?: number;
  duration?: number;
};

export default function TemporalLabel({ text, delay = 0, duration = 5000 }: Props) {
  const { classes } = useStyles();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), delay);
  }, [delay]);

  useEffect(() => {
    setTimeout(() => setVisible(false), delay + duration);
  }, [delay, duration]);

  return (
    <Grow in={visible}>
      <Box className={classes.container}>
        <Box className={classes.triangle} />
        <Typography className={classes.text}>{text}</Typography>
      </Box>
    </Grow>
  );
}
