import API from 'api';
import { Auth, User } from 'firebase/auth';
import { createContext } from 'react';

type Context = {
  auth: Auth;
  api: API;
  currentUser: User | null;
};

const ApiContext = createContext<Context>({} as Context);

export default ApiContext;
