import * as Sentry from '@sentry/react';

import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { ORDER_STATUS, ORDER_STATUS_DISPLAY } from 'consts';
import { useCallback, useContext, useEffect, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import { DatePicker } from '@mui/x-date-pickers';
import ErrorContext from 'contexts/errorContext';
import Paper from '@mui/material/Paper';
import Spinner from 'common/Spinner';
import StoreContext from 'contexts/storeContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDateAndTimeString, getDateFromString } from 'utils';
import SalesReportDialog from 'components/SalesReportDialog';
import { makeStyles } from 'tss-react/mui';
import { saveAs } from 'file-saver';
import { Order } from 'types/order';

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
  },
  emptyState: {
    marginTop: theme.spacing(8),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  exportButton: {
    marginLeft: theme.spacing(3),
  },
  formControl: {
    width: 200,
  },
  headerItem: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default function OrderHistoryScreen() {
  const { classes } = useStyles();
  const { api } = useContext(ApiContext);
  const { store, formatNumber } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);

  const now = new Date();
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedToDate, _setSelectedToDate] = useState(now);
  const [selectedFromDate, _setSelectedFromDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7));
  const [selectedStatuses, setSelectedStatuses] = useState<ORDER_STATUS[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [extension, setExtension] = useState('xlsx');
  const [exporting, setExporting] = useState(false);
  const [salesReportDialogOpen, setSalesReportDialogOpen] = useState(false);

  const setSelectedFromDate = (date: Date | null) => {
    if (date) {
      _setSelectedFromDate(date);
    }
  };

  const setSelectedToDate = (date: Date | null) => {
    if (date) {
      _setSelectedToDate(date);
    }
  };

  const fetchOrders = useCallback(
    (showLoader = true, pageNumber = 1, rows = rowsPerPage) => {
      setLoading(showLoader);
      api.orders
        .list(store.id, pageNumber, rows, selectedStatuses, selectedFromDate.toISOString(), selectedToDate.toISOString())
        .then((res) => {
          setOrders(res.data.results);
          setPageCount(res.data.count);
        })
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [api.orders, rowsPerPage, selectedFromDate, selectedToDate, setError, store.id, selectedStatuses],
  );

  const exportOrders = () => {
    setExporting(true);
    api.orders
      .export(store.id, extension, selectedStatuses, selectedFromDate.toISOString(), selectedToDate.toISOString())
      .then((res) => {
        // TODO: use the filename returned by the server accessing its 'Content-Disposition' header)
        saveAs(new Blob([res.data]), `pedidos.${extension}`);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
        setError(err);
      })
      .finally(() => setExporting(false));
  };

  const handleChangePage = (event: any, page: number) => {
    setCurrentPage(page + 1);
    setLoading(true);
    fetchOrders(true, page + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setCurrentPage(1);
  };

  const handleChangeExtension = (event: any) => {
    setExtension(event.target.value);
  };

  function disableDateAfterTo(date: Date) {
    return date > selectedToDate;
  }

  function disableDateBeforeFrom(date: Date) {
    return date < selectedFromDate;
  }

  useEffect(() => {
    fetchOrders(true, currentPage);
  }, [currentPage, fetchOrders]);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.headerItem}>
          <DatePicker
            format='dd/MM/yyyy'
            label='Fecha de inicio'
            value={selectedFromDate}
            onChange={setSelectedFromDate}
            shouldDisableDate={disableDateAfterTo}
          />
        </Box>
        <Box className={classes.headerItem}>
          <DatePicker
            format='dd/MM/yyyy'
            label='Fecha de fin'
            value={selectedToDate}
            onChange={setSelectedToDate}
            disableFuture
            shouldDisableDate={disableDateBeforeFrom}
          />
        </Box>
        <Box className={classes.headerItem}>
          <FormControl className={classes.formControl}>
            <InputLabel>Estado</InputLabel>
            <Select
              value={selectedStatuses}
              onChange={(event) => setSelectedStatuses(event.target.value as ORDER_STATUS[])}
              renderValue={(selected) => {
                return selected.map((s) => ORDER_STATUS_DISPLAY[s]).join(', ');
              }}
              multiple
              label='Estado'
            >
              {Object.entries(ORDER_STATUS_DISPLAY).map(([value, display]) => (
                <MenuItem value={value} key={value}>
                  <Checkbox color='primary' checked={selectedStatuses.includes(value as ORDER_STATUS)} />
                  {display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {store.id && (
          <Box className={classes.headerItem}>
            <FormControl>
              <InputLabel>Formato</InputLabel>
              <Select value={extension} onChange={handleChangeExtension} label='Formato'>
                <MenuItem value={'xlsx'}>xlsx</MenuItem>
                <MenuItem value={'csv'}>csv</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant='contained'
              color='primary'
              className={classes.exportButton}
              onClick={() => exportOrders()}
              disabled={exporting}
            >
              Exportar
            </Button>
          </Box>
        )}
        <Box className={classes.headerItem}>
          <FormControl>
            <Button variant='contained' color='primary' onClick={() => setSalesReportDialogOpen(true)}>
              Resumen de Ventas
            </Button>
          </FormControl>
        </Box>
      </Box>
      {loading ? (
        <Box marginX={'auto'}>
          <Spinner />
        </Box>
      ) : orders.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Items</TableCell>
                <TableCell>Pago</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.code}</TableCell>
                  <TableCell>{getDateAndTimeString(getDateFromString(order.created_at))}</TableCell>
                  <TableCell>
                    {order.items.map((item) => (
                      <div key={item.id}>
                        ${formatNumber(item.price * item.quantity)} - {item.name} ({item.quantity})
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>{order.purchase ? 'A través de la app' : 'En el local'}</TableCell>
                  <TableCell>${formatNumber(order.store_total)}</TableCell>
                  <TableCell>{order.status_display}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component='div'
            count={pageCount}
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Grid container justifyContent='center'>
          <Typography className={classes.emptyState}>No hay pedidos en el rango seleccionado.</Typography>
        </Grid>
      )}
      <SalesReportDialog isOpen={salesReportDialogOpen} onClose={() => setSalesReportDialogOpen(false)} />
    </>
  );
}
