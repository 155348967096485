import { makeStyles } from 'tss-react/mui';
import { Box, CardHeader, CardMedia, Typography } from '@mui/material';
import { BasePack } from 'types/pack';
import StoreContext from 'contexts/storeContext';
import { useContext } from 'react';

const useStyles = makeStyles()((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    display: 'block',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  name: {
    flex: 1,
    marginRight: theme.spacing(1),
    overflow: 'hidden',
    fontWeight: 600,
    // show only 2 lines
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  cardMedia: {
    minHeight: 80,
    flex: 1,
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  price: {
    fontWeight: 'bold',
  },
}));

type Props = {
  pack: BasePack;
  disabled?: boolean;
};

export default function PackCardHeaderAndImage({ pack, disabled }: Props) {
  const { classes } = useStyles();
  const { formatNumber } = useContext(StoreContext);

  return (
    <>
      <CardHeader
        title={
          <Box className={classes.lineContainer}>
            <Typography variant='body2' className={classes.name}>
              {pack.name}
            </Typography>
            <Typography variant='body2' color='textPrimary' className={classes.price} noWrap>
              ${formatNumber(pack.price)}
            </Typography>
          </Box>
        }
        className={classes.cardHeader}
      />
      <Box position='relative' display='flex' flex={1}>
        <CardMedia
          style={{ filter: disabled ? 'grayscale(80%)' : 'none' }}
          className={classes.cardMedia}
          image={!!pack.image && pack.image.image}
          title={pack.name}
        />
      </Box>
    </>
  );
}
