import { useCallback, useEffect, useRef } from 'react';

// prints the name of the component that mounts/unmounts every time it does so
// custom hook to help debug navigation issues
// usage: useMountLogs('MyComponent');
// enable verbose logging in the console to see these debug logs
export const useMountLogs = (name: string) => {
  useEffect(() => {
    console.debug(name, 'mounted');
    return () => {
      console.debug(name, 'unmounted');
    };
  }, [name]);
};

// custom hook to debug dependency changes in useEffect. drop-in replacement for useEffect.
export const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames: any = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changed = getChangedDeps(previousDeps, dependencies, dependencyNames);

  if (Object.keys(changed).length) {
    console.log('useEffectDebugger ', changed);
  }

  useEffect(effectHook, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

// custom hook to debug dependency changes in useCallback. drop-in replacement for useCallback.
export const useCallbackDebugger = (callbackHook: any, dependencies: any, dependencyNames: any = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changed = getChangedDeps(previousDeps, dependencies, dependencyNames);

  if (Object.keys(changed).length) {
    console.log('useCallbackDebugger ', changed);
  }

  return useCallback(callbackHook, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const getChangedDeps = (previousDeps: any, dependencies: any, dependencyNames: any = []) => {
  const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  return changedDeps;
};
