import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import { formatTimeString } from 'utils';
import { useContext, useState } from 'react';
import { OpeningHoursRequestBeingCreated, OpeningHoursWithRequest } from 'types/openingHours';
import { Check, Delete, Edit } from '@mui/icons-material';
import { OPENING_HOURS_REQUEST_ACTION } from 'consts';
import OpeningHoursDialog from './OpeningHoursDialog';
import ApiContext from 'contexts/apiContext';
import StoreContext from 'contexts/storeContext';
import ErrorContext from 'contexts/errorContext';

type Props = {
  oh: OpeningHoursWithRequest;
  refreshOpeningHourRequests: () => void;
};

export default function OpeningHoursCard({ oh, refreshOpeningHourRequests }: Props) {
  const { api } = useContext(ApiContext);
  const { store } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const isCreate = oh.request && oh.request.action === OPENING_HOURS_REQUEST_ACTION.CREATE;
  const isEdit = oh.request && oh.request.action === OPENING_HOURS_REQUEST_ACTION.EDIT;

  const closeEditDialog = (success: boolean) => {
    setEditDialogOpen(false);
    if (success) refreshOpeningHourRequests();
  };

  const openEditDialog = () => {
    setEditDialogOpen(true);
  };

  const deleteOpeningHour = () => {
    if (window.confirm(`¿Solicitar eliminar horario de retiro?`)) {
      api.openingHoursRequests
        .create(
          {
            target_opening_hour: oh.id,
            action: OPENING_HOURS_REQUEST_ACTION.DELETE,
          },
          store.id,
        )
        .then(refreshOpeningHourRequests)
        .catch(setError);
    }
  };

  const cancelRequest = () => {
    if (window.confirm(`¿Cancelar ${isEdit ? 'edición' : 'eliminación'} de horario de retiro?`)) {
      if (oh.request) {
        api.openingHoursRequests.delete(oh.request.id, store.id).then(refreshOpeningHourRequests).catch(setError);
      }
    }
  };

  return (
    <Card
      sx={{
        px: 2,
        py: 0.5,
        mr: 2,
        my: 0.5,
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        ...(isCreate && { backgroundColor: 'inherit', borderStyle: 'dashed' }),
      }}
      key={oh.id}
      variant={isCreate ? 'outlined' : 'elevation'}
    >
      <Typography sx={{ fontWeight: 500, mr: 2, my: 0.5 }}>{`${formatTimeString(oh.from_time)} a ${formatTimeString(
        oh.to_time,
      )}`}</Typography>
      {oh.request ? (
        <Card sx={{ px: 1, py: 0.2, my: 0.5, backgroundColor: 'transparent' }} variant='outlined'>
          <Box display='flex' flexDirection='row' alignItems='center' flexWrap='wrap'>
            <Check fontSize='small' color='inherit' />
            <Typography variant='body2' sx={{ mx: 0.5 }}>
              {isEdit ? 'Edición' : isCreate ? 'Creación' : 'Eliminación'} solicitada
              {isEdit && `: ${formatTimeString(oh.request.from_time || '')} a ${formatTimeString(oh.request.to_time || '')}`}
            </Typography>
            <Button size='small' onClick={cancelRequest}>
              Cancelar
            </Button>
          </Box>
        </Card>
      ) : (
        <Box sx={{ my: 0.5 }}>
          <IconButton size='small' color='inherit' sx={{ mx: 0.5 }} onClick={openEditDialog}>
            <Edit fontSize='small' />
          </IconButton>
          <IconButton size='small' color='inherit' onClick={deleteOpeningHour}>
            <Delete fontSize='small' />
          </IconButton>
        </Box>
      )}
      <OpeningHoursDialog
        isOpen={editDialogOpen}
        onClose={() => closeEditDialog(false)}
        onSuccess={() => closeEditDialog(true)}
        callback={(editedOhr: OpeningHoursRequestBeingCreated) => api.openingHoursRequests.create(editedOhr, store.id)}
        openingHoursToEdit={oh}
      />
    </Card>
  );
}
