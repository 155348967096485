import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  emptyContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  emptyMessage: {
    fontWeight: 600,
  },
}));

type Props = {
  title: string;
  infoLines: string[];
};

export default function EmptyState({ title, infoLines }: Props) {
  const { classes } = useStyles();

  return (
    <Box className={classes.emptyContainer}>
      <Typography align='center' className={classes.emptyMessage}>
        {title}
      </Typography>
      {infoLines.map((line) => (
        <Typography align='center' key={line}>
          {line}
        </Typography>
      ))}
    </Box>
  );
}
