import { Box, CardMedia, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';

import StoreContext from 'contexts/storeContext';
import logo from 'static/logo-white.png';
import { makeStyles } from 'tss-react/mui';
import { useContext } from 'react';
import ErrorContext from 'contexts/errorContext';

const useStyles = makeStyles()((theme) => ({
  logo: {
    height: theme.spacing(5),
    objectFit: 'contain',
    objectPosition: 'left',
    width: 'auto',
    marginLeft: theme.spacing(2),
    padding: 3,
  },
  squareLogo: {
    marginLeft: theme.spacing(2),
    padding: 3,
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  select: {
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiSelect-root': {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(4),
    },
  },
  selectIcon: {
    color: theme.palette.secondary.main,
  },
  storeLogo: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: '50%',
  },
  storeName: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  selectStoreLogo: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
  },
}));

export default function StoreSelect() {
  const { classes } = useStyles();
  const { store, employeeStores, selectStore } = useContext(StoreContext);
  const mobile = useMediaQuery('(max-width: 600px)');
  const { setError } = useContext(ErrorContext);

  const handleSelectedStore = (event: any) => {
    const storeId = event.target.value;
    const selectedStore = employeeStores.find((store) => store.id === storeId);
    if (selectedStore) {
      selectStore(selectedStore, store);
    } else {
      setError({
        message:
          'Ocurrió un error cambiando de tienda. Por favor intenta de nuevo en unos minutos, o ponte en contacto con nosotros a través de la sección de Ayuda.',
      });
    }
  };

  const SelectedStore = () => (
    <Box className={classes.box}>
      <CardMedia component='img' image={store.logo_image} className={classes.storeLogo} />
      <Typography className={classes.storeName}>{store.name}</Typography>
    </Box>
  );

  return store ? ( // wait for stores to be loaded, else it gets rendered as an uncontrolled component
    <Box className={classes.box}>
      {employeeStores.length > 1 ? (
        <Select
          value={store.id}
          onChange={handleSelectedStore}
          variant='outlined'
          classes={{
            icon: classes.selectIcon,
          }}
          className={classes.select}
          renderValue={SelectedStore}
          size='small'
        >
          {employeeStores.map((store) => (
            <MenuItem key={store.id} value={store.id}>
              <CardMedia component='img' image={store.logo_image} className={classes.selectStoreLogo} />
              <Typography className={classes.storeName}>{store.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      ) : (
        <SelectedStore />
      )}
      {!mobile && <CardMedia component='img' title='Buen Provecho' className={classes.logo} image={logo} />}
    </Box>
  ) : (
    <></>
  );
}
