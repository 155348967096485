import { Box, CircularProgress } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    margin: '50px 0',
    justifyContent: 'center',
  },
}));

export default function Spinner() {
  const { classes } = useStyles();

  return (
    <Box className={classes.box}>
      <CircularProgress color='primary' size='3rem' />
    </Box>
  );
}
