import { createContext } from 'react';
import { EmployeeStore } from 'types/employeeStore';
import { Tag } from 'types/tag';

type Context = {
  store: EmployeeStore;
  employeeStores: EmployeeStore[];
  selectStore: (newStore: EmployeeStore, prevStore?: EmployeeStore) => void;
  tags: Tag[];
  hasPermission: (permission: string) => boolean;
  formatNumber: (value: number) => string;
};

const StoreContext = createContext<Context>({} as Context);

export default StoreContext;
