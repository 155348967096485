import { Box, Card, CardContent, IconButton, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';

import { Alert } from '@mui/material';
import ApiContext from 'contexts/apiContext';
import ConfirmDialog from 'components/ConfirmDialog';
import EditIcon from '@mui/icons-material/Edit';
import ErrorContext from 'contexts/errorContext';
import { PERMISSIONS } from 'consts';
import PackDialog from './PackDialog';
import PermissionTooltip from 'common/PermissionTooltip';
import StoreContext from 'contexts/storeContext';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import SyncIcon from '@mui/icons-material/Sync';
import Toast from 'common/Toast';
import VerticalMenu from 'common/VerticalMenu';
import debounce from 'lodash.debounce';
import { makeStyles } from 'tss-react/mui';
import { Pack, PackBeingCreated } from 'types/pack';
import PackCardHeaderAndImage from './PackCardHeaderAndImage';
import { Diversity3 } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(0.5),
    },
  },
  quantity: {
    width: 72,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    // Hide up/down arrows in number input
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  quantityInput: {
    fontWeight: 'bold',
    fontSize: theme.typography.caption.fontSize,
  },
  iconText: {
    fontWeight: 'bold',
    marginLeft: 3,
    marginRight: 3,
  },
  icon: {
    height: 10,
    width: 10,
    marginLeft: 3,
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  updatedToast: {
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  hidden: {
    fontWeight: 'bold',
  },
  iconButtonText: {
    fontWeight: 'bold',
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  linkedIcon: {
    color: theme.palette.primary.main,
  },
}));

type Props = {
  pack: Pack;
  refreshPacks: () => void;
  packIdBeingEdited?: number;
  setPackIdBeingEdited: (packId?: number) => void;
};

export default function PackCard({ pack, refreshPacks, packIdBeingEdited, setPackIdBeingEdited }: Props) {
  const { api } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const { store, hasPermission } = useContext(StoreContext);
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showUpdatedToast, setShowUpdatedToast] = useState(false);
  // currentQuantity is what should be displayed and updated when adding or subtracting units
  // pack.quantity should only be used to send old_quantity to the backend
  const [currentQuantity, setCurrentQuantity] = useState(0);

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);

  // Action dialogs
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [unlinkDialogIsOpen, setUnlinkDialogIsOpen] = useState(false);

  const editingDisabled = useMemo(
    () => Boolean(loading || (packIdBeingEdited && packIdBeingEdited !== pack.id)),
    [loading, packIdBeingEdited, pack.id],
  );

  const hasEditPermission = hasPermission(PERMISSIONS.EDIT_PACK);

  const handleQuantityChanged = (quantity: number, expectedQuantity: number) => {
    if (quantity !== expectedQuantity) {
      setShowToast(true);
    }
  };

  useEffect(() => {
    setCurrentQuantity(pack.quantity);
  }, [pack]);

  // Wait for some seconds before refreshing packs when user is adding or subtracting units
  // so that the packs don't change order while they do so
  const debouncedEditQuantity = useMemo(() => {
    return debounce((p, quantity) => {
      setLoading(true);
      api.packs
        .quantity(p.id, p.store, p.quantity, quantity)
        .then((res) => {
          setShowUpdatedToast(true);
          handleQuantityChanged(res.data.quantity, quantity);
        })
        .catch((e) => {
          if (e.fieldErrors && 'quantity' in e.fieldErrors) {
            setError({ message: e.fieldErrors.quantity[0] });
          } else {
            setError(true);
          }
        })
        .finally(() => {
          setLoading(false);
          // We want packs to always be refreshed after the edit
          // so that even if it fails the user sees the true quantity in the backend
          refreshPacks();
        });
    }, 1000);
  }, [refreshPacks, api, setError]);

  const updateQuantity = (quantity: number) => {
    // refreshPacks takes care of setting packIdBeingEdited back to null when refresh is done
    setPackIdBeingEdited(pack.id);
    setCurrentQuantity(quantity);
    debouncedEditQuantity(pack, quantity);
  };

  const handleQuantityChange = (event: any) => {
    const quantity = Number(event.target.value);
    updateQuantity(quantity);
  };

  const addUnit = () => updateQuantity(currentQuantity + 1);
  const subtractUnit = () => updateQuantity(currentQuantity - 1);

  const deletePack = () => {
    api.packs
      .delete(pack.id, pack.store)
      .then(() => {
        setDeleteDialogIsOpen(false);
        refreshPacks();
      })
      .catch(setError);
  };

  const duplicatePack = () => {
    api.packs
      .duplicate(pack, pack.store)
      .then(() => {
        setDuplicateDialogIsOpen(false);
        refreshPacks();
      })
      .catch(setError);
  };

  const unlinkPack = () => {
    api.packs
      .unlink(pack, pack.store)
      .then(() => {
        setUnlinkDialogIsOpen(false);
        refreshPacks();
      })
      .catch(setError);
  };

  const menuOptions = useMemo(() => {
    let options = [
      ...(!!pack.organization_pack
        ? [
            {
              name: 'Desvincular',
              action: () => setUnlinkDialogIsOpen(true),
              disabled: !hasEditPermission,
            },
          ]
        : [
            {
              name: 'Duplicar',
              action: () => setDuplicateDialogIsOpen(true),
              disabled: !hasEditPermission,
            },
          ]),
      {
        name: 'Eliminar',
        action: () => setDeleteDialogIsOpen(true),
        disabled: !hasEditPermission,
      },
    ];
    return options;
  }, [hasEditPermission, pack.organization_pack]);

  const actionDialogs = [
    {
      key: 'delete',
      open: deleteDialogIsOpen,
      onClose: () => setDeleteDialogIsOpen(false),
      onConfirm: deletePack,
      title: `¿Eliminar ${pack.name}?`,
      text: 'Todos los datos asociados a este pack se perderán.',
    },
    {
      key: 'duplicate',
      open: duplicateDialogIsOpen,
      onClose: () => setDuplicateDialogIsOpen(false),
      onConfirm: duplicatePack,
      title: `¿Duplicar ${pack.name}?`,
      text: 'Se creará un pack nuevo con la misma información.',
    },
    {
      key: 'unlink',
      open: unlinkDialogIsOpen,
      onClose: () => setUnlinkDialogIsOpen(false),
      onConfirm: unlinkPack,
      title: `¿Desvincular ${pack.name}?`,
      text: 'El pack dejará de estar vinculado al de la organización, y podrás realizar cambios en él para tu tienda.',
    },
  ];

  const handleEditPack = (editedPack: PackBeingCreated) => api.packs.edit(pack.id, editedPack, store.id);

  const closeEditDialog = (success: boolean) => {
    setEditDialogIsOpen(false);
    if (success) {
      setShowUpdatedToast(true);
      refreshPacks();
    }
  };

  const openEditDialog = () => {
    setEditDialogIsOpen(true);
  };

  const handleCloseUpdatedToast = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowUpdatedToast(false);
  };

  const hasAutoPublish = !!pack.auto_publish_qty && !!pack.auto_publish_days;

  const autoPublishStyle = { color: hasAutoPublish ? '#4C9547' : 'gray' };

  return (
    <>
      <Toast
        open={showToast}
        duration={5000}
        onClose={() => setShowToast(false)}
        severity='info'
        message='La cantidad disponible del pack se actualizó automáticamente en base a los pedidos.'
      />
      <Card className={classes.card} style={{ backgroundColor: currentQuantity === 0 || pack.hidden ? 'lightgray' : 'white' }}>
        <PackCardHeaderAndImage pack={pack} disabled={currentQuantity <= 0} />
        <CardContent className={classes.cardContent}>
          {pack.hidden && (
            <Typography align='center' variant='caption' className={classes.hidden}>
              Escondido por un administrador
            </Typography>
          )}
          <Box className={classes.lineContainer}>
            <TextField
              label='Unidades'
              color='primary'
              value={currentQuantity.toString()}
              onChange={handleQuantityChange}
              type='number'
              size='small'
              variant='filled'
              className={classes.quantity}
              InputProps={{ classes: { input: classes.quantityInput } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              disabled={editingDisabled}
            />
            <IconButton
              color='primary'
              size='small'
              onClick={subtractUnit}
              disabled={editingDisabled || currentQuantity === 0}
              title='Restar unidad disponible'
            >
              <Typography className={classes.iconButtonText}>-1</Typography>
            </IconButton>
            <IconButton color='primary' size='small' onClick={addUnit} disabled={editingDisabled} title='Añadir unidad disponible'>
              <Typography className={classes.iconButtonText}>+1</Typography>
            </IconButton>
            <span>
              <PermissionTooltip hasPermission={hasEditPermission}>
                <IconButton color='primary' size='small' onClick={openEditDialog} disabled={!hasEditPermission || editingDisabled}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </PermissionTooltip>
              <VerticalMenu disabled={editingDisabled} options={menuOptions} />
            </span>
          </Box>
          <Box className={classes.lineContainer}>
            <span>
              {!!pack.organization_pack && (
                <>
                  <Diversity3 className={`${classes.icon} ${classes.linkedIcon}`} />
                  <Tooltip
                    title='Este pack está vinculado a un pack compartido. Los cambios en el pack compartido se aplicarán a este también.'
                    placement='bottom'
                    enterTouchDelay={0}
                  >
                    <Typography variant='caption' align='center' className={`${classes.iconText} ${classes.linkedIcon}`} noWrap>
                      Compartido
                    </Typography>
                  </Tooltip>
                </>
              )}
            </span>
            <span>
              {hasAutoPublish ? (
                <SyncIcon className={classes.icon} style={autoPublishStyle} />
              ) : (
                <SyncDisabledIcon className={classes.icon} style={autoPublishStyle} />
              )}
              <Typography variant='caption' align='center' className={classes.iconText} style={autoPublishStyle} noWrap>
                Publicación automática
              </Typography>
            </span>
          </Box>
        </CardContent>
      </Card>
      {actionDialogs.map((dialog) => (
        <ConfirmDialog {...dialog} key={dialog.key} />
      ))}
      <PackDialog
        isOpen={editDialogIsOpen}
        onClose={() => closeEditDialog(false)}
        onSuccess={() => closeEditDialog(true)}
        packToEdit={pack}
        callback={handleEditPack}
      />
      <Snackbar
        open={showUpdatedToast}
        autoHideDuration={3000}
        onClose={handleCloseUpdatedToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity='success' variant='filled' elevation={6} className={classes.updatedToast}>
          Actualizado
        </Alert>
      </Snackbar>
    </>
  );
}
