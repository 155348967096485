import { createContext } from 'react';

type Context = {
  shouldRefreshOrders: boolean;
  refreshOrders: () => void;
  highlightOrderId?: number;
};

const OrdersContext = createContext<Context>({} as Context);

export default OrdersContext;
