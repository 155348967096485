import { Alert, Box, Button, Grid, Snackbar, TextField, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ApiContext from 'contexts/apiContext';
import EmptyState from 'common/EmptyState';
import ErrorContext from 'contexts/errorContext';
import { Link } from 'react-router-dom';
import { PERMISSIONS } from 'consts';
import { Pagination } from '@mui/material';
import PermissionTooltip from 'common/PermissionTooltip';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RefreshIndicator from 'common/RefreshIndicator';
import Spinner from 'common/Spinner';
import StoreContext from 'contexts/storeContext';
import debounce from 'lodash.debounce';
import { makeStyles } from 'tss-react/mui';
import { OrganizationPack, OrganizationPackBeingCreated } from 'types/pack';
import OrganizationPackCard from 'components/OrganizationPackCard';
import OrganizationPackDialog from 'components/OrganizationPackDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import { AddToPhotos } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  topItem: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  newPackButton: {
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
  },
  searchBar: {
    flex: 1,
    minWidth: 200,
    maxWidth: 500,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  toast: {
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

export default function OrganizationPacksScreen() {
  const { classes } = useStyles();
  const { api } = useContext(ApiContext);
  const { store, hasPermission } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [packs, setPacks] = useState<OrganizationPack[]>([]);
  const [search, setSearch] = useState<string>();
  const mobile = useMediaQuery('(max-width: 600px)');
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [cloneDialogIsOpen, setCloneDialogIsOpen] = useState(false);
  const [clonedPacks, setClonedPacks] = useState<number>();

  // pagination
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  const hasEditPermission = hasPermission(PERMISSIONS.EDIT_PACK);

  const refreshPacks = useCallback(
    (search?: string, page?: number) => {
      if (store) {
        setRefreshing(true);
        const params = { search, page };
        api.organizationPacks
          .list(store.organization?.id, params)
          .then((res) => {
            setPacks(res.data.results);
            setNumPages(res.data.num_pages);
          })
          .catch(setError)
          .finally(() => {
            setLoading(false);
            setRefreshing(false);
          });
      }
    },
    [store, api, setError],
  );

  const debouncedRefreshPacks = useMemo(() => debounce((s) => refreshPacks(s), 500), [refreshPacks]);

  const refreshPacksSameParams = useCallback(() => refreshPacks(search, page), [refreshPacks, search, page]);

  useEffect(() => debouncedRefreshPacks(search), [debouncedRefreshPacks, search]);

  const cloneAllPacks = () => {
    api.packs
      .cloneAllFromOrg(store.id)
      .then((res) => setClonedPacks(res.data.length))
      .catch(setError)
      .finally(() => setCloneDialogIsOpen(false));
  };

  const closeDialog = (success: boolean) => {
    setCreateDialogIsOpen(false);
    if (success) refreshPacks();
  };

  const openCreateDialog = () => {
    setCreateDialogIsOpen(true);
  };

  const openCloneDialog = () => {
    setCloneDialogIsOpen(true);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    refreshPacks(search, value);
  };

  const handleCloseClonedToast = (event: any, reason: string) => {
    if (reason === 'clickaway') return;
    setClonedPacks(undefined);
  };

  return (
    <>
      <Typography sx={{ mb: 1 }}>
        Los packs compartidos por tu organización pueden ser utilizados por todas las tiendas que pertenecen a ella.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        Al copiar un pack compartido a una tienda, éste queda vinculado al compartido, y cambios a éste se aplicarán al de la tienda.
      </Typography>
      <Box className={classes.box}>
        <PermissionTooltip hasPermission={hasEditPermission}>
          <Button
            className={`${classes.newPackButton} ${classes.topItem}`}
            variant='contained'
            color='primary'
            onClick={openCreateDialog}
            disabled={!hasEditPermission}
            startIcon={<AddIcon />}
          >
            Pack compartido
          </Button>
        </PermissionTooltip>
        <Link to='../images' relative='path'>
          <Button
            className={`${classes.newPackButton} ${classes.topItem}`}
            variant='contained'
            color='primary'
            startIcon={<PhotoLibraryIcon />}
          >
            Imágenes
          </Button>
        </Link>
        <PermissionTooltip hasPermission={hasEditPermission}>
          <Button
            className={`${classes.newPackButton} ${classes.topItem}`}
            variant='contained'
            color='primary'
            onClick={openCloneDialog}
            disabled={!hasEditPermission}
            startIcon={<AddToPhotos />}
          >
            Copiar todos a mi tienda
          </Button>
        </PermissionTooltip>
        <TextField
          label='Buscar'
          value={search}
          variant='filled'
          type='search'
          onChange={onSearchChange}
          className={`${classes.searchBar} ${classes.topItem}`}
          size='small'
        />
      </Box>
      <OrganizationPackDialog
        isOpen={createDialogIsOpen}
        onClose={() => closeDialog(false)}
        onSuccess={() => closeDialog(true)}
        callback={(pack: OrganizationPackBeingCreated) => api.organizationPacks.create(pack, store.organization?.id)}
      />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <RefreshIndicator visible={refreshing} />
          {packs?.length === 0 ? (
            <EmptyState title='¡Aún no tienes packs compartidos!' infoLines={['Crea tu primer pack para poder usarlo en tus tiendas.']} />
          ) : (
            <Grid container spacing={2}>
              {packs.map((pack) => (
                <Grid item key={pack.id} xs={12} sm={6} md={3} lg={2}>
                  <OrganizationPackCard pack={pack} refreshPacks={refreshPacksSameParams} key={pack.id} />
                </Grid>
              ))}
            </Grid>
          )}
          <Box className={classes.paginationContainer}>
            <Pagination
              count={numPages}
              page={page}
              color='primary'
              size={mobile ? 'small' : 'large'}
              siblingCount={0}
              boundaryCount={1}
              onChange={handlePagination}
            />
          </Box>
        </>
      )}
      <ConfirmDialog
        open={cloneDialogIsOpen}
        onClose={() => setCloneDialogIsOpen(false)}
        onConfirm={cloneAllPacks}
        title='¿Copiar todos los packs?'
        text='Se crearán en tu tienda todos los packs compartidos por tu organización que no hayan sido copiados aún.'
      />
      <Snackbar
        open={clonedPacks !== undefined}
        autoHideDuration={3000}
        onClose={handleCloseClonedToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity='success' variant='filled' elevation={6} className={classes.toast}>
          {clonedPacks} {clonedPacks !== 1 ? 'packs copiados' : 'pack copiado'} a tu tienda.
        </Alert>
      </Snackbar>
    </>
  );
}
