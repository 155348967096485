import { createContext } from 'react';

export type OrderNotification = {
  order_id: number;
  action: string;
};

// Context to publish notification events for components to listen to
// boolean type means nothing: it's just a way to trigger a re-render
// In most cases, components shouldn't check the value, only declare it inside a useEffect hook's dependencies
// Check OrdersPage.tsx for an example, which refreshes the orders when orderNotification changes
type Context = {
  orderNotification?: OrderNotification;
  clearOrderNotification: () => void;
  unreadNotificationsCount: number;
  refreshUnreadNotificationsCount: () => void;
};

const NotificationsContext = createContext<Context>({} as Context);

export default NotificationsContext;
