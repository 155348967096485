import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import ErrorContext from 'contexts/errorContext';

import { makeStyles } from 'tss-react/mui';
import { APIError, FieldErrors, FieldsAPIError } from 'errors';
import { dateToBackendDateString } from 'utils';
import { ClosedDateDateRange } from 'types/closedDate';
import { DatePicker } from '@mui/x-date-pickers';
import ApiContext from 'contexts/apiContext';
import StoreContext from 'contexts/storeContext';

const useStyles = makeStyles()((theme) => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  isDelete: boolean; // else is create
};

// Auxiliary type to control the DatePicker values as Dates until we need to parse them to strings for the backend
type ClosedDateDateRangeNotString = {
  start_date: Date;
  end_date: Date;
};

const getStringRangeFromDateRange = (range: ClosedDateDateRangeNotString): ClosedDateDateRange => ({
  start_date: dateToBackendDateString(range.start_date),
  end_date: dateToBackendDateString(range.end_date),
});

const today = new Date();
const DEFAULT_RANGE: ClosedDateDateRangeNotString = {
  start_date: today,
  end_date: today,
};

export default function ClosedDatesDialog({ isOpen, onClose, onSuccess, isDelete }: Props) {
  const { store } = useContext(StoreContext);
  const { api } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const { classes } = useStyles();
  const [range, setRange] = useState<ClosedDateDateRangeNotString>(DEFAULT_RANGE);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setRange(DEFAULT_RANGE);
      setFieldErrors(undefined);
      setError(false);
    }
  }, [isOpen, setError]);

  const handleError = (err?: APIError) => {
    if (err) {
      if (err instanceof FieldsAPIError) setFieldErrors(err.fieldErrors);
      else setError(err);
    } else {
      setFieldErrors(undefined);
      setError(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleError();
    setLoading(true);
    const stringRange = getStringRangeFromDateRange(range);
    const call = isDelete ? api.closedDates.bulkDelete(stringRange, store.id) : api.closedDates.bulkCreate(stringRange, store.id);
    call
      .then(() => handleClose(true))
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const handleClose = (success = false) => {
    handleError();
    success ? onSuccess() : onClose();
  };

  const handleStartDateChange = (value: any) => {
    // Set both start and end date when selecting a new start date
    setRange({
      start_date: value,
      end_date: value,
    });
  };

  const handleEndDateChange = (value: any) => {
    setRange({
      ...range,
      end_date: value,
    });
  };

  const getFieldError = (field: string) => fieldErrors && fieldErrors[field];

  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)} fullWidth>
      <DialogTitle>{isDelete ? 'Eliminar' : 'Añadir'} fechas de cierre</DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={handleSubmit}>
          <FormControl className={classes.field} required fullWidth>
            <DatePicker
              label='Fecha de inicio'
              value={range.start_date}
              onChange={handleStartDateChange}
              name='start_date'
              format='dd/MM/yyyy'
            />
            {!!getFieldError('start_date') && (
              <FormHelperText variant='filled' error={true}>
                {getFieldError('start_date')}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.field} required fullWidth>
            <DatePicker label='Fecha de fin' value={range.end_date} onChange={handleEndDateChange} name='end_date' format='dd/MM/yyyy' />
            {!!getFieldError('end_date') && (
              <FormHelperText variant='filled' error={true}>
                {getFieldError('end_date')}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color='primary'>
          Cancelar
        </Button>
        <Button disabled={loading} variant='contained' onClick={handleSubmit} color='primary'>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
