import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ApiContext from 'contexts/apiContext';
import EmptyState from 'common/EmptyState';
import ErrorContext from 'contexts/errorContext';
import { Link } from 'react-router-dom';
import { PERMISSIONS } from 'consts';
import PackCard from 'components/PackCard';
import PackDialog from 'components/PackDialog';
import { Pagination } from '@mui/material';
import PermissionTooltip from 'common/PermissionTooltip';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RefreshIndicator from 'common/RefreshIndicator';
import Spinner from 'common/Spinner';
import StoreContext from 'contexts/storeContext';
import Toast from 'common/Toast';
import debounce from 'lodash.debounce';
import { makeStyles } from 'tss-react/mui';
import { Pack, PackBeingCreated } from 'types/pack';
import { Diversity3 } from '@mui/icons-material';
import { useMountLogs } from 'hooks';

const useStyles = makeStyles()((theme) => ({
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  topItem: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  newPackButton: {
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
  },
  searchBar: {
    flex: 1,
    minWidth: 200,
    maxWidth: 500,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));

export default function PacksScreen() {
  const { classes } = useStyles();
  const { api } = useContext(ApiContext);
  const { store, hasPermission } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [packs, setPacks] = useState<Pack[]>([]);
  const [search, setSearch] = useState<string>();
  const [activeOnly, setActiveOnly] = useState(false);
  const [autoPublishedOnly, setAutoPublishedOnly] = useState(false);
  const mobile = useMediaQuery('(max-width: 600px)');
  const [packIdBeingEdited, setPackIdBeingEdited] = useState<number>();

  useMountLogs('PacksScreen');

  // pagination
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  const hasEditPermission = hasPermission(PERMISSIONS.EDIT_PACK);

  const refreshPacks = useCallback(
    (search?: string, page?: number) => {
      if (store) {
        setRefreshing(true);
        let params: any = { search, page };
        if (activeOnly) {
          params['quantity__gt'] = 0;
        }
        if (autoPublishedOnly) {
          params['auto_publish_qty__gt'] = 0;
        }
        api.packs
          .list(store.id, params)
          .then((res) => {
            setPacks(res.data.results);
            setNumPages(res.data.num_pages);
          })
          .catch(setError)
          .finally(() => {
            setLoading(false);
            setRefreshing(false);
            setPackIdBeingEdited(undefined);
          });
      }
    },
    [store, api, setError, activeOnly, autoPublishedOnly],
  );

  const debouncedRefreshPacks = useMemo(() => debounce((s) => refreshPacks(s), 500), [refreshPacks]);

  const refreshPacksSameParams = useCallback(() => refreshPacks(search, page), [refreshPacks, search, page]);

  useEffect(() => debouncedRefreshPacks(search), [debouncedRefreshPacks, search]);

  const closeDialog = (success: boolean) => {
    setDialogIsOpen(false);
    if (success) refreshPacks();
  };

  const openDialog = () => {
    setDialogIsOpen(true);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    refreshPacks(search, value);
  };

  const handleActiveOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setActiveOnly(event.target.checked);
  };

  const handleAutoPublishedOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setAutoPublishedOnly(event.target.checked);
  };

  return (
    <>
      <Box className={classes.box}>
        <PermissionTooltip hasPermission={hasEditPermission}>
          <Button
            className={`${classes.newPackButton} ${classes.topItem}`}
            variant='contained'
            color='primary'
            onClick={openDialog}
            disabled={!hasEditPermission}
            startIcon={<AddIcon />}
          >
            Pack
          </Button>
        </PermissionTooltip>
        <Link to='images'>
          <Button
            className={`${classes.newPackButton} ${classes.topItem}`}
            variant='contained'
            color='primary'
            startIcon={<PhotoLibraryIcon />}
          >
            Imágenes
          </Button>
        </Link>
        {!!store.organization && (
          <Link to='shared'>
            <Button
              className={`${classes.newPackButton} ${classes.topItem}`}
              variant='contained'
              color='primary'
              startIcon={<Diversity3 />}
            >
              Packs compartidos
            </Button>
          </Link>
        )}
        <FormControlLabel
          className={classes.topItem}
          control={<Checkbox checked={activeOnly} onChange={handleActiveOnly} color='primary' />}
          label={
            <Typography variant='body2' noWrap sx={{ fontWeight: 500 }}>
              Sólo activos
            </Typography>
          }
        />
        <FormControlLabel
          className={classes.topItem}
          control={<Checkbox checked={autoPublishedOnly} onChange={handleAutoPublishedOnly} color='primary' />}
          label={
            <Typography variant='body2' noWrap sx={{ fontWeight: 500 }}>
              Sólo con publicación automática
            </Typography>
          }
        />
        <TextField
          label='Buscar'
          value={search}
          variant='filled'
          type='search'
          onChange={onSearchChange}
          className={`${classes.searchBar} ${classes.topItem}`}
          size='small'
        />
      </Box>
      <PackDialog
        isOpen={dialogIsOpen}
        onClose={() => closeDialog(false)}
        onSuccess={() => closeDialog(true)}
        callback={(pack: PackBeingCreated) => api.packs.create(pack, store.id)}
      />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <RefreshIndicator visible={refreshing} />
          <Toast
            open={showToast}
            duration={5000}
            onClose={() => setShowToast(false)}
            severity='info'
            message='La cantidad disponible del pack se actualizó automáticamente en base a los pedidos.'
          />
          {packs?.length === 0 ? (
            <EmptyState title='¡Aún no tienes packs!' infoLines={['Publica tu primer pack para que sea mostrado en la app.']} />
          ) : (
            <Grid container spacing={2}>
              {packs.map((pack) => (
                <Grid item key={pack.id} xs={12} sm={6} md={3} lg={2}>
                  <PackCard
                    pack={pack}
                    refreshPacks={refreshPacksSameParams}
                    packIdBeingEdited={packIdBeingEdited}
                    setPackIdBeingEdited={setPackIdBeingEdited}
                    key={pack.id}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          <Box className={classes.paginationContainer}>
            <Pagination
              count={numPages}
              page={page}
              color='primary'
              size={mobile ? 'small' : 'large'}
              siblingCount={0}
              boundaryCount={1}
              onChange={handlePagination}
            />
          </Box>
        </>
      )}
    </>
  );
}
