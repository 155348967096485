import { Box, Grid, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import ErrorContext from 'contexts/errorContext';
import OrderCard from 'components/OrderCard';
import { Pagination } from '@mui/material';
import RefreshIndicator from 'common/RefreshIndicator';
import Spinner from 'common/Spinner';
import StoreContext from 'contexts/storeContext';
import { Order } from 'types/order';
import OrdersContext from 'contexts/ordersContext';

const PAGE_SIZE = 20;

type Props = {
  title: string;
  statuses: string[];
  daysBack: number;
};

export default function OrderList({ title, statuses, daysBack }: Props) {
  const { api } = useContext(ApiContext);
  const { store } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);
  const { shouldRefreshOrders } = useContext(OrdersContext);

  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchOrders = useCallback(
    (pageNumber = 1) => {
      setRefreshing(true);
      const now = new Date();
      // Note: subtracting days from getDate() works fine even if result is negative
      const createdAfter = new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysBack);
      api.orders
        .list(store.id, pageNumber, PAGE_SIZE, statuses, createdAfter.toISOString())
        .then((res) => {
          setOrders(res.data.results);
          setPageCount(Math.ceil(res.data.count / PAGE_SIZE));
        })
        .catch(setError)
        .finally(() => {
          setRefreshing(false);
          setLoading(false);
        });
    },
    [api, setError, statuses, store.id, daysBack],
  );

  const onPageChange = (event: any, page: number) => {
    setCurrentPage(page);
    fetchOrders(page);
  };

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage, fetchOrders, shouldRefreshOrders]);

  return (
    <>
      <Grid container item spacing={2} xs={12} sm={6} alignContent='flex-start'>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ fontWeight: 600 }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <RefreshIndicator visible={!loading && refreshing} />
            {loading ? (
              <Box marginX={'auto'}>
                <Spinner />
              </Box>
            ) : orders.length > 0 ? (
              orders.map((order) => (
                <Grid item key={order.id} xs={12}>
                  <OrderCard order={order} />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography>
                  No hay pedidos {title.toLowerCase()} en {daysBack === 1 ? 'el último día' : `los últimos ${daysBack} días`}.
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box mt={3} marginX={'auto'}>
            <Pagination
              sx={{ mt: 2 }}
              count={pageCount}
              onChange={onPageChange}
              page={currentPage}
              style={{ justifyContent: 'center', display: 'flex' }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
