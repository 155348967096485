import * as Sentry from '@sentry/react';
import API from 'api';

import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';

import { LocalStorageKeys } from 'localStorageKeys';

export async function retrieveAndSendNotificationToken(api: API, storeId: number) {
  const isMessagingSupported = await isSupported();
  if (isMessagingSupported && storeId) {
    const messaging = getMessaging();
    try {
      const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_VAPID_KEY });
      const { id } = await api.notificationTokens.create(storeId, token).then((res) => res.data);
      localStorage.setItem(LocalStorageKeys.NOTIFICATIONS_TOKEN_ID, id);
    } catch (err: any) {
      if (err.code !== 'messaging/permission-blocked') {
        Sentry.captureException(err);
        console.error(err);
      }
    }
  }
}

export async function deleteNotificationToken(api: API, storeId: number) {
  const tokenId = localStorage.getItem(LocalStorageKeys.NOTIFICATIONS_TOKEN_ID);
  localStorage.removeItem(LocalStorageKeys.NOTIFICATIONS_TOKEN_ID);
  if (tokenId && storeId) {
    api.notificationTokens.delete(storeId, tokenId).catch(console.error);
  }
}

export async function listenForNotifications() {
  const isMessagingSupported = await isSupported();
  if (isMessagingSupported) {
    const result = await Notification.requestPermission();
    if (result === 'granted') {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        const title = payload.notification?.title || 'Nueva notificación';
        const body = payload.notification?.body;
        const order_id = payload.data?.order_id;
        const notification = new Notification(title, { body, requireInteraction: true, icon: '/ms-icon-310x310.png' });
        notification.onclick = () => {
          notification.close();
          window.location.href = `${window.location.origin}/app/orders?newOrderId=${order_id}`;
        };
      });
    }
  }
}
