import { Add, Remove } from '@mui/icons-material';
import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import ClosedDatesDialog from './ClosedDatesDialog';
import { useCallback, useContext, useEffect, useState } from 'react';
import StoreContext from 'contexts/storeContext';
import ApiContext from 'contexts/apiContext';
import { ClosedDate } from 'types/closedDate';
import ErrorContext from 'contexts/errorContext';
import Spinner from 'common/Spinner';

export default function ClosedDates() {
  const { store } = useContext(StoreContext);
  const { api } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [closedDates, setClosedDates] = useState<ClosedDate[]>([]);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const refreshClosedDates = useCallback(() => {
    api.closedDates
      .list_not_paginated(store.id)
      .then((res) => setClosedDates(res.data))
      .catch(setError)
      .finally(() => setLoading(false));
  }, [api, setError, store]);

  useEffect(refreshClosedDates, [refreshClosedDates]);

  const openCreateDialog = () => setCreateDialogIsOpen(true);
  const openDeleteDialog = () => setDeleteDialogIsOpen(true);

  const closeCreateDialog = (success: boolean) => {
    setCreateDialogIsOpen(false);
    if (success) refreshClosedDates();
  };
  const closeDeleteDialog = (success: boolean) => {
    setDeleteDialogIsOpen(false);
    if (success) refreshClosedDates();
  };

  return (
    <Box marginTop={2}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Button variant='contained' color='primary' startIcon={<Add />} sx={{ mr: 2, mt: 1 }} onClick={openCreateDialog}>
            Añadir fechas
          </Button>
          <Button variant='text' color='primary' startIcon={<Remove />} sx={{ mr: 2, mt: 1 }} onClick={openDeleteDialog}>
            Eliminar fechas
          </Button>
          <Table sx={{ mt: 2 }}>
            <TableBody>
              {closedDates.map((closedDate) => (
                <TableRow key={closedDate.date}>
                  <TableCell width='100%' sx={{ py: 1 }}>
                    <Typography sx={{ fontWeight: 500, mr: 2, my: 0.5 }}>{closedDate.date}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ClosedDatesDialog
            isOpen={createDialogIsOpen}
            onClose={() => closeCreateDialog(false)}
            onSuccess={() => closeCreateDialog(true)}
            isDelete={false}
          />
          <ClosedDatesDialog
            isOpen={deleteDialogIsOpen}
            onClose={() => closeDeleteDialog(false)}
            onSuccess={() => closeDeleteDialog(true)}
            isDelete={true}
          />
        </>
      )}
    </Box>
  );
}
