import { AlertColor } from '@mui/material';
import { createContext } from 'react';

export type Toast = {
  message?: string;
  duration?: number;
  severity?: AlertColor;
  onClose?: () => void;
};

const ToastContext = createContext({
  setToast: (toast: Toast) => {},
});

export default ToastContext;
