import * as Sentry from '@sentry/react';
import Auth from 'screens/Auth';
import { ROUTER_SCREENS } from 'routing';
import App from 'screens/App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Navigate, Route, RouteProps, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import ErrorScreen from 'components/Error';
import Content from 'screens/Content';
import MercadoPagoRedirectScreen from 'screens/content/MercadoPagoRedirectScreen';
import LoginScreen from 'screens/auth/LoginScreen';
import ImpersonateScreen from 'screens/auth/ImpersonateScreen';
import ResetPasswordScreen from 'screens/auth/ResetPasswordScreen';
import ResetPasswordConfirmScreen from 'screens/auth/ResetPasswordConfirmScreen';
import Error from 'components/Error';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new Sentry.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

// default props to apply to all routes
const props: RouteProps = { errorElement: <Error /> };

const routes = createRoutesFromElements(
  <Route {...props} path='*' element={<App />}>
    <Route {...props} path='app' element={<Content />}>
      {ROUTER_SCREENS.map((item) => (
        <Route {...props} path={item.path} key={item.path} element={item.content} />
      ))}
      <Route {...props} path='error' element={<ErrorScreen />} />
      <Route {...props} path='mercadopago/failure' element={<MercadoPagoRedirectScreen success={false} />} />
      <Route {...props} path='mercadopago/success' element={<MercadoPagoRedirectScreen success={true} />} />
      <Route {...props} path='' element={<Navigate to='packs' />} />
      <Route {...props} path='*' element={<Navigate to='packs' />} />
    </Route>
    <Route {...props} path='auth' element={<Auth />}>
      <Route {...props} path='login' element={<LoginScreen />} />
      <Route {...props} path='impersonate/:token/:storeId?' element={<ImpersonateScreen />} />
      <Route {...props} path='reset-password' element={<ResetPasswordScreen />} />
      <Route {...props} path='reset-password-confirm' element={<ResetPasswordConfirmScreen />} />
      <Route {...props} path='' element={<Navigate to='login' />} />
      <Route {...props} path='*' element={<Navigate to='login' />} />
    </Route>
    <Route {...props} path='*' element={<Navigate to='app' replace={true} />} />
  </Route>,
);

const container = document.getElementById('root');
const root = createRoot(container!);
let router = createBrowserRouter(routes);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`).then(
    () => {},
    (error) => {
      Sentry.captureException(error);
      console.error(`Service worker registration failed: ${error}`);
    },
  );
} else {
  console.error('Service workers are not supported.');
}

export { router };
