import { Grid } from '@mui/material';
import { useContext } from 'react';

import StoreContext from 'contexts/storeContext';
import StoreImageGallery from 'components/StoreImageGallery';

export default function StoreImagesScreen() {
  const { store } = useContext(StoreContext);

  const hasOrg = !!store.organization;

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} sm={hasOrg ? 6 : 12}>
        <StoreImageGallery fullWidth={!hasOrg} />
      </Grid>
      {hasOrg && (
        <Grid item xs={12} sm={6}>
          <StoreImageGallery org />
        </Grid>
      )}
    </Grid>
  );
}
