import { getAuth } from '@firebase/auth';
class WebSocketAPI {
  baseUrl = `wss://${process.env.REACT_APP_BACKEND_URL}/ws/`;

  async _getSocket(endpoint: string): Promise<WebSocket> {
    const currentUser = getAuth().currentUser;
    let url = `${this.baseUrl}${endpoint}`;
    if (currentUser) {
      const token = await currentUser.getIdToken();
      url = `${url}?auth_token=${token}`;
    }
    return new WebSocket(url);
  }

  async getOrderNotificationsSocket(storeId: number): Promise<WebSocket> {
    return this._getSocket(`stores/${storeId}/orders/notify/`);
  }
}

export const webSocketAPI = new WebSocketAPI();
