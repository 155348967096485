import { Grid, Typography } from '@mui/material';
import ClosedDates from 'components/ClosedDates';
import OpeningHours from 'components/OpeningHours';

export default function OpeningHoursScreen() {
  return (
    <Grid container spacing={10}>
      <Grid item xs={12} lg={7}>
        <Typography variant='h6' sx={{ fontWeight: 600, mb: 1 }}>
          Horarios de retiro
        </Typography>
        <Typography sx={{ mb: 0.3 }} variant='body2'>
          Solicita que se cree, edite, o elimine un horario de retiro.
        </Typography>
        <Typography sx={{ mb: 0.3 }} variant='body2'>
          Los cambios en los horarios son aplicados cuando finaliza el último horario de retiro de tu tienda cada día.
        </Typography>
        <Typography variant='body2'>Puedes tener más de un horario de retiro por día.</Typography>
        <OpeningHours />
      </Grid>
      <Grid item xs={12} lg={5}>
        <Typography variant='h6' sx={{ fontWeight: 600, mb: 1 }}>
          Fechas de cierre
        </Typography>
        <Typography sx={{ mb: 0.3 }} variant='body2'>
          Configura fechas en la que tu tienda estará cerrada, por ejemplo, por feriados o vacaciones.
        </Typography>
        <Typography variant='body2'>
          Las fechas de cierre deben ser configurados como máximo el día anterior antes de que finalice tu último horario de retiro.
        </Typography>
        <ClosedDates />
      </Grid>
    </Grid>
  );
}
