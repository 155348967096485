import { Button, Container, Typography } from '@mui/material';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 50,
    color: theme.palette.primary.main,
  },
  error: {
    color: '#5F021F',
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));

type Props = {
  success: boolean;
};

export default function MercadoPagoRedirectScreen({ success }: Props) {
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      {success ? <DoneAllIcon className={classes.icon} /> : <ErrorOutlineIcon className={`${classes.icon} ${classes.error}`} />}
      <Typography variant='h5' className={classes.margin}>
        {success ? '¡Listo!' : '¡Ups!'}
      </Typography>
      <Typography className={classes.margin}>
        {success
          ? '¡Tu cuenta de MercadoPago fue vinculada con éxito! Ya puedes recibir pagos a través de la app.'
          : 'Ocurrió un problema vinculando tu cuenta de MercadoPago. Intenta de nuevo, o si el problema persiste ponte en contacto con nosotros a través de la sección de Ayuda.'}
      </Typography>
      <Link to='/app/store'>
        <Button variant='contained' color='primary'>
          Volver a mi tienda
        </Button>
      </Link>
    </Container>
  );
}
