import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import ErrorContext from 'contexts/errorContext';
import StoreContext from 'contexts/storeContext';
import { Tag } from 'types/tag';
import { APIError, FieldErrors, FieldsAPIError } from 'errors';
import { OrganizationPack, OrganizationPackBeingCreated } from 'types/pack';
import BasePackDialogFields from './PackDialog/BasePackDialogFields';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  packToEdit?: OrganizationPack;
  callback: (pack: OrganizationPackBeingCreated) => Promise<any>;
};

export default function OrganizationPackDialog({ isOpen, onClose, onSuccess, packToEdit, callback }: Props) {
  const { store } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);
  const [pack, setPack] = useState<OrganizationPackBeingCreated>({});
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (packToEdit) {
      let packCopy: OrganizationPackBeingCreated = {
        ...packToEdit,
        tags: packToEdit.tags.map((tag: Tag) => tag.id),
        image: packToEdit.image.id,
      };
      setPack(packCopy);
      setIsEdit(true);
    } else {
      setPack({
        organization: store.organization?.id,
        tax_type: store?.default_tax_type,
      });
      setIsEdit(false);
    }
  }, [isOpen, packToEdit, store]);

  const handleError = (err?: APIError) => {
    if (err) {
      if (err instanceof FieldsAPIError) setFieldErrors(err.fieldErrors);
      else setError(err);
    } else {
      setFieldErrors(undefined);
      setError(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleError(undefined);
    setLoading(true);
    const result = callback(pack);
    result
      .then(() => handleClose(true))
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const handleClose = (success = false) => {
    if (!isEdit) {
      setPack({});
    }
    handleError(undefined);
    success ? onSuccess() : onClose();
  };

  const handleChange = (event: any) => {
    setPack((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const getFieldError = (field: string) => fieldErrors && fieldErrors[field];

  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)} fullWidth>
      <DialogTitle>{isEdit ? 'Editar' : 'Crear'} pack compartido</DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={handleSubmit}>
          <BasePackDialogFields
            isOpen={isOpen}
            pack={pack}
            packToEdit={packToEdit}
            handleChange={handleChange}
            getFieldError={getFieldError}
            onClose={onClose}
            orgImagesOnly={true}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color='primary'>
          Cancelar
        </Button>
        <Button disabled={loading} variant='contained' onClick={handleSubmit} color='primary'>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
