import { Box, Card, CardContent, IconButton, Snackbar } from '@mui/material';
import { useContext, useMemo, useState } from 'react';

import { Alert } from '@mui/material';
import ApiContext from 'contexts/apiContext';
import ConfirmDialog from 'components/ConfirmDialog';
import ErrorContext from 'contexts/errorContext';
import { PERMISSIONS } from 'consts';
import StoreContext from 'contexts/storeContext';
import { makeStyles } from 'tss-react/mui';
import { OrganizationPack, PackBeingCreated } from 'types/pack';
import PackCardHeaderAndImage from './PackCardHeaderAndImage';
import PermissionTooltip from 'common/PermissionTooltip';
import { AddToPhotos, Edit } from '@mui/icons-material';
import VerticalMenu from 'common/VerticalMenu';
import OrganizationPackDialog from './OrganizationPackDialog';

const useStyles = makeStyles()((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(0.5),
    },
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  quantity: {
    width: 72,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    // Hide up/down arrows in number input
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  quantityInput: {
    fontWeight: 'bold',
    fontSize: theme.typography.caption.fontSize,
  },
  autoPublish: {
    fontWeight: 'bold',
    flex: 1,
  },
  autoPublishIcon: {
    height: 10,
    width: 10,
    marginRight: 5,
  },
  toast: {
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  hidden: {
    fontWeight: 'bold',
  },
  iconButtonText: {
    fontWeight: 'bold',
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}));

type Props = {
  pack: OrganizationPack;
  refreshPacks: () => void;
};

export default function OrganizationPackCard({ pack, refreshPacks }: Props) {
  const { api } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const { store, hasPermission } = useContext(StoreContext);
  const { classes } = useStyles();
  const [showUpdatedToast, setShowUpdatedToast] = useState(false);
  const [showClonedToast, setShowClonedToast] = useState(false);

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);

  // Action dialogs
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [cloneDialogIsOpen, setCloneDialogIsOpen] = useState(false);

  const hasEditPermission = hasPermission(PERMISSIONS.EDIT_PACK);

  const deletePack = () => {
    api.organizationPacks
      .delete(pack.id, pack.organization)
      .then(() => refreshPacks())
      .catch(setError)
      .finally(() => setCloneDialogIsOpen(false));
  };

  const clonePack = () => {
    api.packs
      .cloneFromOrg(pack, store.id)
      .then(() => setShowClonedToast(true))
      .catch(setError)
      .finally(() => setCloneDialogIsOpen(false));
  };

  const menuOptions = useMemo(
    () => [
      {
        name: 'Eliminar',
        action: () => setDeleteDialogIsOpen(true),
        disabled: !hasEditPermission,
      },
    ],
    [hasEditPermission],
  );

  const actionDialogs = [
    {
      key: 'clone',
      open: cloneDialogIsOpen,
      onClose: () => setCloneDialogIsOpen(false),
      onConfirm: clonePack,
      title: `¿Copiar ${pack.name}?`,
      text: 'Se creará un nuevo pack en tu tienda vinculado a este pack compartido.',
    },
    {
      key: 'delete',
      open: deleteDialogIsOpen,
      onClose: () => setDeleteDialogIsOpen(false),
      onConfirm: deletePack,
      title: `¿Eliminar ${pack.name}?`,
      text: 'Todos los datos asociados a este pack se perderán.',
    },
  ];

  const handleEditPack = (editedPack: PackBeingCreated) => api.organizationPacks.edit(pack.id, editedPack, pack.organization);

  const closeEditDialog = (success: boolean) => {
    setEditDialogIsOpen(false);
    if (success) {
      setShowUpdatedToast(true);
      refreshPacks();
    }
  };

  const handleCloseUpdatedToast = (event: any, reason: string) => {
    if (reason === 'clickaway') return;
    setShowUpdatedToast(false);
  };

  const handleCloseClonedToast = (event: any, reason: string) => {
    if (reason === 'clickaway') return;
    setShowClonedToast(false);
  };

  return (
    <>
      <Card className={classes.card}>
        <PackCardHeaderAndImage pack={pack} />
        <CardContent className={classes.cardContent}>
          <Box className={classes.lineContainer}>
            <PermissionTooltip hasPermission={hasEditPermission}>
              <IconButton
                color='primary'
                size='small'
                onClick={() => setEditDialogIsOpen(true)}
                disabled={!hasEditPermission}
                title='Editar'
              >
                <Edit fontSize='small' />
              </IconButton>
            </PermissionTooltip>
            <PermissionTooltip hasPermission={hasEditPermission}>
              <IconButton
                color='primary'
                size='small'
                onClick={() => setCloneDialogIsOpen(true)}
                disabled={!hasEditPermission}
                title='Copiar a mi tienda'
              >
                <AddToPhotos fontSize='small' />
              </IconButton>
            </PermissionTooltip>
            <VerticalMenu options={menuOptions} />
          </Box>
        </CardContent>
      </Card>
      {actionDialogs.map((dialog) => (
        <ConfirmDialog {...dialog} />
      ))}
      <OrganizationPackDialog
        isOpen={editDialogIsOpen}
        onClose={() => closeEditDialog(false)}
        onSuccess={() => closeEditDialog(true)}
        packToEdit={pack}
        callback={handleEditPack}
      />
      <Snackbar
        open={showClonedToast}
        autoHideDuration={3000}
        onClose={handleCloseClonedToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity='success' variant='filled' elevation={6} className={classes.toast}>
          Copiado a tu tienda
        </Alert>
      </Snackbar>
      <Snackbar
        open={showUpdatedToast}
        autoHideDuration={3000}
        onClose={handleCloseUpdatedToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity='success' variant='filled' elevation={6} className={classes.toast}>
          Actualizado
        </Alert>
      </Snackbar>
    </>
  );
}
