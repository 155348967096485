import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import ErrorContext from 'contexts/errorContext';
import StoreContext from 'contexts/storeContext';
import { OPENING_HOURS_REQUEST_ACTION, WEEKDAYS } from 'consts';
import { makeStyles } from 'tss-react/mui';
import { APIError, FieldErrors, FieldsAPIError } from 'errors';
import { OpeningHours, OpeningHoursRequestBeingCreated } from 'types/openingHours';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getTimeString, getDateFromTimeString } from 'utils';

const useStyles = makeStyles()((theme) => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  openingHoursToEdit?: OpeningHours;
  callback: (openingHoursRequest: OpeningHoursRequestBeingCreated) => Promise<any>;
};

const DEFAULT_OPENING_TIME = '08:00:00';
const DEFAULT_CLOSING_TIME = '20:00:00';

const DEFAULT_CREATE_OPENING_HOURS_REQUEST: OpeningHoursRequestBeingCreated = {
  action: OPENING_HOURS_REQUEST_ACTION.CREATE,
  from_time: DEFAULT_OPENING_TIME,
  to_time: DEFAULT_CLOSING_TIME,
};

export default function OpeningHoursDialog({ isOpen, onClose, onSuccess, openingHoursToEdit, callback }: Props) {
  const { store } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);

  const { classes } = useStyles();
  const [openingHoursRequest, setOpeningHoursRequest] = useState<OpeningHoursRequestBeingCreated>(DEFAULT_CREATE_OPENING_HOURS_REQUEST);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
  const [loading, setLoading] = useState(false);

  const isEdit = !!openingHoursToEdit;

  useEffect(() => {
    if (openingHoursToEdit) {
      setOpeningHoursRequest({
        target_opening_hour: openingHoursToEdit.id,
        action: OPENING_HOURS_REQUEST_ACTION.EDIT,
        from_time: openingHoursToEdit.from_time,
        to_time: openingHoursToEdit.to_time,
      });
    } else {
      setOpeningHoursRequest(DEFAULT_CREATE_OPENING_HOURS_REQUEST);
    }
  }, [isOpen, openingHoursToEdit, store]);

  const handleError = (err?: APIError) => {
    if (err) {
      if (err instanceof FieldsAPIError) setFieldErrors(err.fieldErrors);
      else setError(err);
    } else {
      setFieldErrors(undefined);
      setError(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleError(undefined);
    setLoading(true);
    callback(openingHoursRequest)
      .then(() => handleClose(true))
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const handleClose = (success = false) => {
    handleError(undefined);
    success ? onSuccess() : onClose();
  };

  const handleChange = (event: any) => {
    setOpeningHoursRequest((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleTimePickerChange = (field: string, value: any) => {
    setOpeningHoursRequest((prevState) => ({
      ...prevState,
      [field]: getTimeString(value, true),
    }));
  };

  const getFieldError = (field: string) => fieldErrors && fieldErrors[field];

  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)} fullWidth>
      <DialogTitle>Solicitar {isEdit ? 'edición' : 'creación'} de horario de retiro</DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={handleSubmit}>
          {!isEdit && (
            <FormControl className={classes.field} required fullWidth>
              <InputLabel>Día de la semana</InputLabel>
              <Select
                name='weekday'
                value={openingHoursRequest['weekday'] || ''}
                onChange={handleChange}
                label='Tipo de impuesto'
                error={!!getFieldError('weekday')}
              >
                {WEEKDAYS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {!!getFieldError('weekday') && (
                <FormHelperText variant='filled' error={true}>
                  {getFieldError('weekday')}
                </FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl className={classes.field} required fullWidth>
            <TimePicker
              label='Hora de inicio'
              value={getDateFromTimeString(openingHoursRequest.from_time)}
              onChange={(v) => handleTimePickerChange('from_time', v)}
              name='from_time'
              ampm={false}
            />
            {!!getFieldError('from_time') && (
              <FormHelperText variant='filled' error={true}>
                {getFieldError('from_time')}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.field} required fullWidth>
            <TimePicker
              label='Hora de fin'
              value={getDateFromTimeString(openingHoursRequest.to_time)}
              onChange={(v) => handleTimePickerChange('to_time', v)}
              name='to_time'
              ampm={false}
            />
            {!!getFieldError('to_time') && (
              <FormHelperText variant='filled' error={true}>
                {getFieldError('to_time')}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color='primary'>
          Cancelar
        </Button>
        <Button disabled={loading} variant='contained' onClick={handleSubmit} color='primary'>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
