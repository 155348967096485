import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import EmptyState from 'common/EmptyState';
import ErrorContext from 'contexts/errorContext';
import { PERMISSIONS } from 'consts';
import { Pagination } from '@mui/material';
import PermissionTooltip from 'common/PermissionTooltip';
import PublishIcon from '@mui/icons-material/Publish';
import RefreshIndicator from 'common/RefreshIndicator';
import Spinner from 'common/Spinner';
import StoreContext from 'contexts/storeContext';
import StoreImageComponent from 'components/StoreImage';
import { makeStyles } from 'tss-react/mui';
import { StoreImage } from 'types/storeImage';
import StoreImagesDialog from 'components/StoreImagesDialog';

const useStyles = makeStyles()((theme) => ({
  uploadButton: {
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  org?: boolean;
  fullWidth?: boolean;
};

export default function StoreImageGallery({ org, fullWidth }: Props) {
  const { classes } = useStyles();
  const { api } = useContext(ApiContext);
  const { store, hasPermission } = useContext(StoreContext);
  const [storeImages, setStoreImages] = useState<StoreImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const { setError } = useContext(ErrorContext);
  const mobile = useMediaQuery('(max-width: 600px)');
  const [dialogOpen, setDialogOpen] = useState(false);

  // pagination
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  // xs is bigger than sm when not fullWidth because when going from sm to xs the two StoreImageGallerys
  // go from being side by side to one on top of the other in StoreImagesScreen
  const imageBreakpoints = fullWidth ? { xs: 4, sm: 3, md: 2, lg: 1.5 } : { xs: 4, sm: 6, md: 4, lg: 3 };

  const handlePagination = (event: any, value: number) => setPage(value);

  const hasManageImagesPermission = hasPermission(PERMISSIONS.MANAGE_IMAGES);

  const refreshStoreImages = useCallback(() => {
    setRefreshing(true);
    api.storeImages
      .list(store.id, { page, ...(org ? { org__notnull: true } : { store__notnull: true }) })
      .then((res) => {
        setStoreImages(res.data.results);
        setNumPages(res.data.num_pages);
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
        setRefreshing(false);
      });
  }, [api, setError, store.id, page, org]);

  useEffect(() => {
    refreshStoreImages();
  }, [refreshStoreImages]);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant='h6' sx={{ fontWeight: 600, mb: 1 }}>
          Imágenes {org && 'compartidas'}
        </Typography>
        <Typography sx={{ mb: 1 }}>
          Sube o elimina imágenes para {org ? 'ser usadas por todas las tiendas de tu organización.' : 'usar en los packs de tu tienda.'}
        </Typography>
        <PermissionTooltip hasPermission={hasManageImagesPermission}>
          <Button
            className={classes.uploadButton}
            variant='contained'
            component='span'
            color='primary'
            disabled={!hasManageImagesPermission}
            startIcon={<PublishIcon />}
            onClick={() => setDialogOpen(true)}
          >
            Subir imágenes
          </Button>
        </PermissionTooltip>
      </Box>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <RefreshIndicator visible={refreshing} />
          {storeImages?.length === 0 ? (
            <EmptyState
              title='¡Aún no tienes imágenes!'
              infoLines={[
                'Sube imágenes para poder usarlas en tus packs.',
                'Ten en cuenta que las imágenes deben ser aprobadas por un administrador antes de ser utilizadas.',
              ]}
            />
          ) : (
            <Grid container spacing={2}>
              {storeImages.map((image) => (
                <Grid item key={image.id} {...imageBreakpoints}>
                  <StoreImageComponent storeImage={image} refreshStoreImages={refreshStoreImages} />
                </Grid>
              ))}
            </Grid>
          )}
          <Box className={classes.paginationContainer}>
            <Pagination
              count={numPages}
              page={page}
              color='primary'
              size={mobile ? 'small' : 'large'}
              siblingCount={0}
              boundaryCount={1}
              onChange={handlePagination}
            />
          </Box>
        </>
      )}
      <StoreImagesDialog isOpen={dialogOpen} close={() => setDialogOpen(false)} onSuccess={refreshStoreImages} isForOrg={org} />
    </>
  );
}
