import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material';

import ApiContext from 'contexts/apiContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorContext from 'contexts/errorContext';
import InfoIcon from '@mui/icons-material/Info';
import { PERMISSIONS } from 'consts';
import PermissionTooltip from 'common/PermissionTooltip';
import { STORE_IMAGE_STATUS } from 'consts';
import StoreContext from 'contexts/storeContext';
import { makeStyles } from 'tss-react/mui';
import { useContext } from 'react';
import { StoreImage } from 'types/storeImage';

const useStyles = makeStyles()((theme) => ({
  card: {
    position: 'relative',
    aspectRatio: 1,
    backgroundColor: theme.palette.background.default,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  id: {
    height: 26,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1,
    borderRadius: 100,
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  idText: {
    fontWeight: 600,
  },
  delete: {
    height: 26,
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    borderRadius: 100,
    backgroundColor: theme.palette.background.default,
  },
  status: {
    color: 'white',
    fontWeight: 500,
    flex: 1,
  },
  statusContainer: {
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'flex-end',
  },
  statusContainerInner: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    width: '100%',
  },
}));

type Props = {
  storeImage: StoreImage;
  refreshStoreImages: () => void;
};

export default function StoreImageComponent({ storeImage, refreshStoreImages }: Props) {
  const { classes } = useStyles();
  const { api } = useContext(ApiContext);
  const { store, hasPermission } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);

  const approved = storeImage.status === STORE_IMAGE_STATUS.APPROVED;
  const pending = storeImage.status === STORE_IMAGE_STATUS.PENDING_APPROVAL;
  const rejected = storeImage.status === STORE_IMAGE_STATUS.REJECTED;

  const canDeleteImage = hasPermission(PERMISSIONS.MANAGE_IMAGES);

  const onDelete = () => {
    if (window.confirm('¿Eliminar imagen?')) {
      api.storeImages
        .delete(storeImage.id, store.id)
        .then(refreshStoreImages)
        .catch((err) => {
          console.error(err);
          setError(err);
        });
    }
  };

  return (
    <Card className={classes.card}>
      <img src={storeImage.image} className={classes.image} alt='imagen de pack' />
      {!approved && (
        <Box className={classes.statusContainer} style={{ height: rejected ? '100%' : undefined }}>
          <Box className={classes.statusContainerInner}>
            <Typography className={classes.status} variant='body2' noWrap>
              {storeImage.status_display}
            </Typography>
            <Tooltip
              title={
                pending
                  ? 'Esta imagen está pendiente de ser aprobada por un administrador.'
                  : storeImage.rejected_reason
                    ? `Imagen rechazada por un administrador con motivo: ${storeImage.rejected_reason}.`
                    : 'Imagen rechazada por un administrador.'
              }
              placement='bottom'
              enterTouchDelay={0}
            >
              <IconButton size='small' color='secondary'>
                <InfoIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
      <PermissionTooltip hasPermission={canDeleteImage}>
        <Card className={classes.delete}>
          <IconButton disabled={!canDeleteImage} color='primary' size='small' onClick={onDelete}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        </Card>
      </PermissionTooltip>
      <Card className={classes.id}>
        <Typography className={classes.idText} variant='caption'>
          ID {storeImage.id}
        </Typography>
      </Card>
    </Card>
  );
}
