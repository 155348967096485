import * as Sentry from '@sentry/react';
import { Box, Pagination } from '@mui/material';
import Spinner from 'common/Spinner';
import NotificationCard from 'components/NotificationCard';
import ApiContext from 'contexts/apiContext';
import StoreContext from 'contexts/storeContext';
import { useContext, useEffect, useState } from 'react';
import { Notification } from 'types/notification';
import NotificationsContext from 'contexts/notificationsContext';

const page_size = 6;

export default function NotificationsScreen() {
  const { api } = useContext(ApiContext);
  const { store } = useContext(StoreContext);
  const { refreshUnreadNotificationsCount } = useContext(NotificationsContext);
  const [notifications, setNotifications] = useState<Notification[]>();
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    api.notifications
      .list(store.id, { page, page_size })
      .then((res) => {
        setNotifications(res.data.results);
        setPageCount(res.data.num_pages);
        return res.data.results;
      })
      .then((results) => {
        // Mark all visible unread notifications as read
        const unread = results.filter((notification) => !notification.read);
        if (unread) {
          api.notifications
            .bulkRead(
              store.id,
              unread.map((notification) => notification.id),
            )
            .then(refreshUnreadNotificationsCount)
            .catch((err) => {
              console.error(err);
              Sentry.captureException(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  }, [api, store.id, page, refreshUnreadNotificationsCount]);

  return notifications === undefined ? (
    <Spinner />
  ) : (
    <Box maxWidth={800} display='flex' flexDirection='column' alignItems='center'>
      {notifications?.map((notification) => <NotificationCard notification={notification} key={notification.id} />)}
      <Pagination sx={{ mt: 1 }} count={pageCount} page={page} onChange={(_, page) => setPage(page)} />
    </Box>
  );
}
