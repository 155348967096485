import * as Sentry from '@sentry/react';

import { Box, Button, Container, Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Fragment, useCallback, useContext } from 'react';
import { CountryCode, PaymentGateway, PERMISSIONS, STATSIG_FLAGS } from 'consts';
import { capitalizeFirstLetter } from 'utils';

import ApiContext from 'contexts/apiContext';
import ErrorContext from 'contexts/errorContext';
import PermissionTooltip from 'common/PermissionTooltip';
import StoreContext from 'contexts/storeContext';
import { makeStyles } from 'tss-react/mui';
import { Store } from 'types/store';
import { Link } from 'react-router-dom';
import { Info } from '@mui/icons-material';
import useGate from 'statsig-react/dist/useGate';
import { Link as MuiLink } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: 1000,
  },
  banner: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: 10,
  },
  logoAndNameContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: 50,
    borderColor: theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: 5,
    position: 'absolute',
    top: -50,
    left: 20,
    // Center horizontally in mobile
    [theme.breakpoints.down('sm')]: {
      left: 0,
      right: 0,
      margin: 'auto',
    },
  },
  storeName: {
    fontWeight: 700,
    marginTop: 10,
    // Logo on left in desktop
    [theme.breakpoints.up('sm')]: {
      marginLeft: 140,
    },
    // Logo on top in mobile
    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
      textAlign: 'center',
      width: '100%',
    },
  },
  sectionHeading: {
    marginTop: theme.spacing(2),
  },
  openingHour: {
    marginRight: theme.spacing(1),
  },
  sectionContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  otherContainer: {
    marginTop: theme.spacing(2),
  },
  infoContainer: {
    marginTop: theme.spacing(4),
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  otherHeader: {
    fontWeight: 600,
    marginRight: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
}));

type MainField = {
  label: string;
  value: keyof Store;
  description?: string;
};

const mainFields: MainField[] = [
  { label: 'Descripción', value: 'description' },
  { label: 'Dirección', value: 'address' },
  { label: 'Categoría', value: 'category' },
  {
    label: 'Teléfono de contacto',
    value: 'contact_phone_number',
    description: 'Sólo para comunicación con los administradores de la aplicación. No se muestra a los usuarios.',
  },
];

type FieldNameAndValueProps = {
  name: string;
  children: React.ReactNode;
};

function FieldNameAndValue({ name, children }: FieldNameAndValueProps) {
  const { classes } = useStyles();

  return (
    <Fragment key={name}>
      <Grid item xs={12} sm={4} md={3} className={classes.fieldContainer}>
        <Typography variant='h6'>{name}</Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={9} className={classes.fieldContainer}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          {children}
        </Box>
      </Grid>
    </Fragment>
  );
}

type InfoTooltipProps = {
  text: React.ReactNode;
};
function InfoTooltip({ text }: InfoTooltipProps) {
  const theme = useTheme();

  return (
    <Tooltip title={text} sx={{ color: theme.palette.primary.main, ml: 1 }} placement='bottom' enterTouchDelay={0}>
      <IconButton size='small'>
        <Info fontSize='small' />
      </IconButton>
    </Tooltip>
  );
}

export default function StoreScreen() {
  const { classes } = useStyles();
  const { store, hasPermission } = useContext(StoreContext);
  const { api } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const mercadoPagoUruguayEnabled = useGate(STATSIG_FLAGS.CONNECT_MP_UY_ENABLED).value;

  const hasConnectMercadoPagoPermission = hasPermission(PERMISSIONS.CONNECT_MERCADOPAGO);
  const connectMercadoPagoEnabled = store.country.country_code !== CountryCode.URUGUAY || mercadoPagoUruguayEnabled;

  const onConnectMercadoPago = useCallback(() => {
    api.mercadoPago
      .getOAuthLink(store.id)
      .then((res) => {
        window.location.href = res.data.link;
      })
      .catch((err) => {
        Sentry.captureException(err);
        setError(err);
      });
  }, [api, store.id, setError]);

  return (
    <Container className={classes.container}>
      <Box component='img' src={store.banner_image} className={classes.banner} />
      <Box className={classes.logoAndNameContainer}>
        <Box component='img' src={store.logo_image} className={classes.logo} />
        <Typography className={classes.storeName} variant='h4'>
          {store.name}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mainFields.map((field) => (
          <FieldNameAndValue name={field.label} key={field.value}>
            <Typography>{store[field.value] as string}</Typography>
            {!!field.description && <InfoTooltip text={field.description} />}
          </FieldNameAndValue>
        ))}
        <FieldNameAndValue name={'Organización'}>
          <Typography>{store.organization ? store.organization.name : 'Tu tienda no pertenece a una organización.'}</Typography>
          <InfoTooltip
            text={
              'Que tu tienda pertenezca a una organización permite compartir packs e imágenes con otras tiendas de la misma, ' +
              'facilitando la gestión. Ponte en contacto con nosotros a través de la sección de Ayuda para más información.'
            }
          />
        </FieldNameAndValue>
        <FieldNameAndValue name={'Pago en el local'}>
          <Typography display='inline'>{store.accepts_in_store_payment ? 'Sí' : 'No'}</Typography>
        </FieldNameAndValue>
        <FieldNameAndValue name={'Pago online'}>
          <Typography display='inline'>{store.has_in_app_payments ? 'Sí' : 'No'}</Typography>
          {connectMercadoPagoEnabled &&
            store.country.payment_gateways.includes(PaymentGateway.MERCADOPAGO) &&
            !store.payment_gateways.includes(PaymentGateway.MERCADOPAGO) && (
              <>
                <PermissionTooltip hasPermission={hasConnectMercadoPagoPermission}>
                  <Button
                    onClick={onConnectMercadoPago}
                    className={classes.marginLeft}
                    variant='contained'
                    color='primary'
                    size='small'
                    disabled={!hasConnectMercadoPagoPermission}
                  >
                    Conectar MercadoPago
                  </Button>
                </PermissionTooltip>
                <InfoTooltip
                  text={
                    <>
                      <Typography variant='caption'>
                        Aceptar pagos online en tu tienda reduce la tasa de pedidos cancelados, asegura el cobro de pedidos abandonados y
                        simplifica el pago de comisiones.
                        <Box>
                          <MuiLink
                            href='https://youtu.be/aeq4xu_IHzM'
                            target='_blank'
                            sx={{ color: '#d1ffbd', fontWeight: 600, textDecoration: 'underline' }}
                          >
                            Ver tutorial
                          </MuiLink>
                        </Box>
                      </Typography>
                    </>
                  }
                />
              </>
            )}
        </FieldNameAndValue>
      </Grid>
      <Typography className={classes.sectionHeading} variant='h6'>
        Otros
      </Typography>
      <Box className={classes.otherContainer}>
        <Typography className={classes.otherHeader} display='inline'>
          Calificación
        </Typography>
        <Typography display='inline'>
          {store.reviews_count > 0 ? `${store.average_rating.toFixed(1)} / 5 (${store.reviews_count} reseñas)` : 'Todavía no hay reseñas'}
        </Typography>
        <Link to='reviews'>
          <Button
            className={classes.marginLeft}
            variant='contained'
            color='primary'
            size='small'
            disabled={!hasConnectMercadoPagoPermission}
          >
            Ver reseñas
          </Button>
        </Link>
      </Box>
      <Box className={classes.otherContainer}>
        <Typography className={classes.otherHeader} display='inline'>
          Medios de pago en tienda
        </Typography>
        <Typography display='inline'>{capitalizeFirstLetter(store.accepted_payment_methods_display.join(', '))}</Typography>
      </Box>
      <Box className={classes.otherContainer}>
        <Typography className={classes.otherHeader} display='inline'>
          Retiro con recipiente propio
        </Typography>
        <Typography display='inline'>{store.allows_byobox ? 'Sí' : 'No'}</Typography>
      </Box>
      <Box className={`${classes.sectionContainer} ${classes.infoContainer}`}>
        <Typography variant='body2'>
          Ponte en contacto con nosotros en la sección de Ayuda para modificar la información de tu tienda.
        </Typography>
      </Box>
    </Container>
  );
}
