import * as Sentry from '@sentry/react';

import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import ApiContext from 'contexts/apiContext';
import ErrorContext from 'contexts/errorContext';
import StoreContext from 'contexts/storeContext';
import { useContext, useState } from 'react';
import { Publish } from '@mui/icons-material';

type Props = {
  isOpen: boolean;
  close: () => void;
  onSuccess: () => void;
  isForOrg?: boolean;
};

export default function StoreImagesDialog({ isOpen, close, onSuccess, isForOrg }: Props) {
  const { store } = useContext(StoreContext);
  const { api } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const [uploading, setUploading] = useState(false);

  const onUploadImages = (event: any) => {
    setUploading(true);
    api.storeImages
      .bulkCreate(event.target.files, store.id, isForOrg)
      .then(() => {
        onSuccess();
        close();
      })
      .catch((err) => {
        console.error(err);
        if (err.errors) {
          if (err.errors[0].image) {
            setError({ message: err.errors[0].image });
          }
        } else {
          Sentry.captureException(err);
          setError(err);
        }
      })
      .finally(() => setUploading(false));
  };

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>Subir imágenes para mi {isForOrg ? 'organización' : 'tienda'}</DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={{ mb: 1 }}>
          Ten en cuenta que las imágenes deberán ser aprobadas por un administrador de la aplicación antes de ser utilizadas en tus packs.
        </Typography>
        <Typography variant='body2'>Puedes seleccionar más de una imagen en la ventana que abre el botón de abajo.</Typography>
        {/* see backend/backend/compression.py for accepted image formats */}
        <Box sx={{ mt: 2 }}>
          <input type='file' multiple onChange={onUploadImages} hidden id='input' accept='.jpg,.jpeg,.png,.webp' disabled={uploading} />
          <label htmlFor='input'>
            <Button variant='contained' component='span' color='primary' startIcon={<Publish />} disabled={uploading}>
              Seleccionar imágenes y subir
            </Button>
          </label>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
