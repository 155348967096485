import { AccessTimeFilled, Fastfood, FindInPage, Help, ShoppingCart, Store } from '@mui/icons-material';
import HelpScreen from 'screens/content/HelpScreen';
import OrderHistoryScreen from 'screens/content/OrderHistoryScreen';
import OrdersScreen from 'screens/content/OrdersScreen';
import PacksScreen from 'screens/content/PacksScreen';
import ReviewsScreen from 'screens/content/ReviewsScreen';
import StoreScreen from 'screens/content/StoreScreen';
import StoreImagesScreen from 'screens/content/StoreImagesScreen';
import OpeningHoursScreen from 'screens/content/OpeningHoursScreen';
import OrganizationPacksScreen from 'screens/content/OrganizationPacksScreen';
import NotificationsScreen from 'screens/content/NotificationsScreen';

// Note: only screens defined in the top level will be shown in the left menu
// Screens inside the children array need to be navigatable from their parent
export const SCREENS: Screen[] = [
  {
    name: 'Packs',
    icon: <Fastfood color='primary' />,
    content: <PacksScreen />,
    path: 'packs',
    children: [
      {
        name: 'Imágenes',
        path: 'images',
        content: <StoreImagesScreen />,
      },
      {
        name: 'Packs compartidos',
        path: 'shared',
        content: <OrganizationPacksScreen />,
      },
    ],
  },
  {
    name: 'Pedidos actuales',
    icon: <ShoppingCart color='primary' />,
    content: <OrdersScreen />,
    path: 'orders',
  },
  {
    name: 'Historial de pedidos',
    icon: <FindInPage color='primary' />,
    content: <OrderHistoryScreen />,
    path: 'order-history',
  },
  {
    name: 'Mi tienda',
    icon: <Store color='primary' />,
    content: <StoreScreen />,
    path: 'store',
    children: [
      {
        name: 'Reseñas',
        path: 'reviews',
        content: <ReviewsScreen />,
      },
    ],
  },
  {
    name: 'Horarios',
    path: 'times',
    icon: <AccessTimeFilled color='primary' />,
    content: <OpeningHoursScreen />,
  },
  {
    name: 'Ayuda',
    icon: <Help color='primary' />,
    content: <HelpScreen />,
    path: 'help',
  },
  {
    name: 'Notificaciones',
    content: <NotificationsScreen />,
    path: 'notifications',
    notShownInDrawer: true,
  },
];

export type Screen = {
  name: string;
  content: JSX.Element;
  path: string;
  icon?: JSX.Element; // Only needs to be defined for top-level (left menu) screens
  children?: Screen[];
  notShownInDrawer?: boolean; // Set true for screens that aren't nested inside other ones but aren't shown in the left menu either
};

export type RouterScreen = {
  breadCrumbs: string[];
  content: JSX.Element;
  path: string;
  parentPath?: string;
};

export let ROUTER_SCREENS: RouterScreen[] = [];
function flattenScreens(screens: Screen[], parent?: RouterScreen) {
  screens.forEach((screen) => {
    const path = (parent ? parent.path + '/' : '') + screen.path;
    const routerScreen = {
      breadCrumbs: parent ? parent.breadCrumbs.concat(screen.name) : [screen.name],
      content: screen.content,
      path: path,
      parentPath: parent?.path,
    };
    ROUTER_SCREENS.push(routerScreen);
    screen.children && flattenScreens(screen.children, routerScreen);
  });
}
flattenScreens(SCREENS);
