import * as Sentry from '@sentry/react';

import { Box, Container, IconButton, Typography } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { deleteNotificationToken, retrieveAndSendNotificationToken } from 'notifications';

import ApiContext from 'contexts/apiContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorContext from 'contexts/errorContext';
import { LocalStorageKeys } from 'localStorageKeys';
import Spinner from 'common/Spinner';
import StoreContext from 'contexts/storeContext';

import { makeStyles } from 'tss-react/mui';
import { signOut } from 'firebase/auth';
import { EmployeeStore } from 'types/employeeStore';
import { Tag } from 'types/tag';
import HeaderAndDrawer from 'components/HeaderAndDrawer';
import { ROUTER_SCREENS, RouterScreen } from 'routing';
import NotificationsContext, { OrderNotification } from 'contexts/notificationsContext';
import { webSocketAPI } from 'websockets';
import TermsAndConditionsDialog from 'components/TermsAndConditionsDialog';
import { useMountLogs } from 'hooks';
import { router } from 'index';
import GlobalContext from 'contexts/globalContext';

const basePath = '/app';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: {
    ...(theme.mixins.toolbar as CSSProperties),
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  pageTitle: {
    fontWeight: 'bold',
    alignItems: 'center',
    display: 'flex',
  },
  pageTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
  },
}));

export default function Content() {
  const { classes } = useStyles();
  const { api, currentUser, auth } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const { selectedStoreId } = useContext(GlobalContext);
  const currentPath = useLocation().pathname;
  const [loading, setLoading] = useState(true);
  const [employeeStores, setEmployeeStores] = useState<EmployeeStore[]>();
  const [store, setStore] = useState<EmployeeStore>();
  const [tags, setTags] = useState<Tag[]>([]);
  const [currentScreen, setCurrentScreen] = useState<RouterScreen>();
  const [orderNotification, setOrderNotification] = useState<OrderNotification>();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  useMountLogs('Content');

  useEffect(() => {
    if (store) {
      let websocket: WebSocket | undefined = undefined;
      webSocketAPI.getOrderNotificationsSocket(store.id).then((ws) => {
        websocket = ws;
        ws.onmessage = function (event) {
          setOrderNotification(JSON.parse(event.data));
        };
      });
      return () => websocket?.close();
    }
  }, [store]);

  const clearOrderNotification = useCallback(() => {
    setOrderNotification(undefined);
  }, []);

  useEffect(() => {
    setCurrentScreen(ROUTER_SCREENS.find((item) => currentPath === basePath + '/' + item.path));
  }, [currentPath]);

  useEffect(() => {
    if (!localStorage.getItem(LocalStorageKeys.LOGGED_IN)) {
      router.navigate('/auth');
    }
  }, []);

  const logoutWithoutConfirmation = useCallback(() => {
    signOut(auth)
      .then(() => {
        setStore(undefined);
      })
      .catch(console.error)
      .finally(() => router.navigate('/auth'));
  }, [auth]);

  const logout = useCallback(
    // receiving store as parameter to avoid setting it as a dependency
    (s: EmployeeStore) => {
      if (window.confirm('¿Cerrar sesión?')) {
        deleteNotificationToken(api, s.id).finally(logoutWithoutConfirmation);
      }
    },
    [logoutWithoutConfirmation, api],
  );

  const selectStore = useCallback(
    (newStore: EmployeeStore, prevStore: EmployeeStore | undefined = undefined) => {
      // remove old notification token if another store was already selected
      prevStore && deleteNotificationToken(api, prevStore.id);
      // create notification token of new store
      retrieveAndSendNotificationToken(api, newStore.id);
      // set selected store in context
      setStore(newStore);

      // set store id as tag in Sentry
      Sentry.setTag('store', newStore.id);

      // let backend know user selected this store so it is the most recently used one next time
      // no need to show any error to the user if this fails
      api.employeeStores.select(newStore.id).catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
    },
    [api],
  );

  const hasPermission = useCallback((permission: string) => !!store?.permissions?.includes(permission), [store]);

  useEffect(() => {
    store &&
      api.tags
        .list_not_paginated(store.id)
        .then((res) => setTags(res.data))
        .catch(setError);
  }, [api, setError, store]);

  const refreshStores = useCallback(() => {
    setLoading(true);
    if (currentUser) {
      api.employeeStores
        .list()
        .then((res) => {
          // TODO: move this check directly to auth screen and show error message there
          // https://buenprovecho.atlassian.net/browse/DEV-438
          if (!res || res.data.length === 0) {
            // logout users who are not employees of any store
            logoutWithoutConfirmation();
          } else {
            const stores = res.data.map((r) => ({
              ...r.store,
              permissions: r.permissions,
              last_time_selected: r.last_time_selected,
            }));
            setEmployeeStores(stores);
            let storeToSelect: EmployeeStore | undefined;
            if (!!selectedStoreId) {
              // if selectedStoreId exists, set that one (it was opened from the admin)
              storeToSelect = stores.find((store) => store.id === selectedStoreId);
            }
            if (!storeToSelect) {
              // else set store with newest "last_time_selected" which is the one user was using last time
              // "last_time_selected" is in the format "2023-05-14T09:21:32.535570Z" so can be compared as string
              storeToSelect = stores.reduce(
                (previous: EmployeeStore, current: EmployeeStore) =>
                  previous.last_time_selected > current.last_time_selected ? previous : current,
                stores[0],
              );
            }
            selectStore(storeToSelect);
          }
        })
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [api, setError, currentUser, logoutWithoutConfirmation, selectStore, selectedStoreId]);

  useEffect(() => refreshStores(), [refreshStores]);

  const formatNumber = useMemo(() => {
    const format = Intl.NumberFormat(store?.country.locale);
    return format.format;
  }, [store]);

  const acceptTermsAndConditions = () => {
    store &&
      api.termsAndConditionsApprovals
        .create({ terms_and_conditions: store.terms_and_conditions_to_approve.id }, store.id)
        .then(refreshStores)
        .catch((e) => {
          setError(e);
          console.error(e);
          Sentry.captureException(e);
        });
  };

  const refreshUnreadNotificationsCount = useCallback(() => {
    store &&
      api.notifications
        .unreadCount(store.id)
        .then((res) => {
          setUnreadNotificationsCount(res.data.unread_count);
        })
        .catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
  }, [api, store]);

  useEffect(() => {
    refreshUnreadNotificationsCount();
  }, [refreshUnreadNotificationsCount]);

  return (
    employeeStores &&
    store && (
      <StoreContext.Provider value={{ store, employeeStores, selectStore, tags, hasPermission, formatNumber }}>
        <NotificationsContext.Provider
          value={{ orderNotification, clearOrderNotification, unreadNotificationsCount, refreshUnreadNotificationsCount }}
        >
          <div className={classes.root}>
            <HeaderAndDrawer logout={() => logout(store)} basePath={basePath} />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth={false} className={classes.container}>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <Box className={classes.pageTitleContainer}>
                      {/* show goBack icon if we're in an inner screen */}
                      {currentScreen?.parentPath && (
                        <Link to={currentScreen.parentPath} className={classes.link}>
                          <IconButton color='primary' size='large' sx={{ mr: 0.5 }}>
                            <ArrowBackIcon fontSize='small' />
                          </IconButton>
                        </Link>
                      )}
                      {currentScreen?.breadCrumbs.map((item, index) => (
                        <Typography key={item} className={classes.pageTitle} variant='h6'>
                          {index > 0 && <ChevronRightIcon fontSize='small' sx={{ mx: 0.5 }} />} {item}
                        </Typography>
                      ))}
                    </Box>
                    <Outlet />
                  </>
                )}
              </Container>
            </main>
          </div>
          {!!store.terms_and_conditions_to_approve && (
            <TermsAndConditionsDialog logout={() => logout(store)} acceptTermsAndConditions={acceptTermsAndConditions} />
          )}
        </NotificationsContext.Provider>
      </StoreContext.Provider>
    )
  );
}
