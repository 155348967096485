import {
  AppBar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuIcon from '@mui/icons-material/Menu';
import StoreSelect from 'components/StoreSelect';
import { makeStyles } from 'tss-react/mui';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { Link, useLocation } from 'react-router-dom';
import { SCREENS } from 'routing';
import { isCurrentOpeningHour, formatPickupDatetimes, formatPickupTime } from 'utils';
import StoreContext from 'contexts/storeContext';
import { DirectionsWalk, Notifications } from '@mui/icons-material';
import NotificationsContext from 'contexts/notificationsContext';
import { useMountLogs } from 'hooks';

const APPBAR_WIDTH = 230;

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    paddingRight: 24,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 10px',
    ...(theme.mixins.toolbar as CSSProperties),
  },
  appBar: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: APPBAR_WIDTH,
    width: `calc(100% - ${APPBAR_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: APPBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClosed: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  selectedItem: {
    backgroundColor: grey[200],
  },
  menuText: {
    fontWeight: 500,
    fontSize: 14,
  },
  indicator: {
    color: 'lightgray',
    height: 20,
    width: 20,
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1.5),
  },
  indicatorOn: {
    color: '#66FF00', // bright green
  },
}));

type Props = {
  logout: () => void;
  basePath: string;
};

export default function HeaderAndMenu({ logout, basePath }: Props) {
  const { classes, cx } = useStyles();
  const { store } = useContext(StoreContext);
  const { unreadNotificationsCount } = useContext(NotificationsContext);
  const currentPath = useLocation().pathname;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pickupText, setPickupText] = useState('');

  useMountLogs('HeaderAndDrawer');

  useEffect(() => {
    // Check if store is open once a minute (60000ms)
    const updateIsOpen = () => setIsOpen(isCurrentOpeningHour(store.next_dated_opening_hour));
    updateIsOpen(); // Check on mount instead of waiting a minute for the first update
    const interval = setInterval(updateIsOpen, 60000);
    return () => clearInterval(interval); // Remove interval on unmount
  }, [store.next_dated_opening_hour]);

  useEffect(() => {
    let text;
    if (isOpen) {
      text =
        'Te encuentras dentro de un horario de retiro' +
        (store.next_dated_opening_hour ? `: ${formatPickupTime(store.next_dated_opening_hour)}.` : '.');
    } else {
      text =
        'No te encuentras dentro de un horario de retiro.' +
        (store.next_dated_opening_hour && ` Tu próximo horario de retiro es ${formatPickupDatetimes([store.next_dated_opening_hour])}.`);
    }
    setPickupText(text);
  }, [isOpen, store]);

  return (
    <>
      <AppBar className={cx(classes.appBar, drawerOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            onClick={() => setDrawerOpen(true)}
            className={cx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
            size='large'
          >
            <MenuIcon />
          </IconButton>
          <Box display='flex' flex={1} alignItems='center' justifyContent='flex-end'>
            <Link to={'notifications'}>
              <IconButton color='secondary' title='Notificaciones'>
                <Badge badgeContent={unreadNotificationsCount} color='warning' max={9} overlap='circular'>
                  <Notifications />
                </Badge>
              </IconButton>
            </Link>
            <Tooltip title={pickupText} placement='bottom' enterTouchDelay={0}>
              <DirectionsWalk className={cx(classes.indicator, isOpen && classes.indicatorOn)} />
            </Tooltip>
            <StoreSelect />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: cx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClosed),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => setDrawerOpen(false)} size='large'>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {SCREENS.filter((s) => !s.notShownInDrawer).map((item) => {
            const path = basePath + '/' + item.path;
            return (
              <Link to={path} key={item.name} className={classes.link}>
                <ListItem className={currentPath.startsWith(path) ? classes.selectedItem : ''}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={<Typography className={classes.menuText}>{item.name}</Typography>} />
                </ListItem>
              </Link>
            );
          })}
          <ListItem onClick={logout}>
            <ListItemIcon>
              <MeetingRoomIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={<Typography className={classes.menuText}>Cerrar sesión</Typography>} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
