import { Add, Delete, Edit, Info } from '@mui/icons-material';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { OPENING_HOURS_REQUEST_ACTION } from 'consts';
import { OpeningHoursWithRequest } from 'types/openingHours';
import { formatTimeString, getWeekdayName } from 'utils';

type Props = {
  rejectedOpeningHoursRequests: OpeningHoursWithRequest[];
  isOpen: boolean;
  close: () => void;
};

const getRejectedOpeningHoursRequestText = (ohr: OpeningHoursWithRequest) => {
  if (!ohr.request) return ''; // Should never happen
  if (ohr.request.action === OPENING_HOURS_REQUEST_ACTION.CREATE) {
    return `Crear ${getWeekdayName(ohr.request.weekday)} de ${formatTimeString(ohr.request.from_time)} a ${formatTimeString(
      ohr.request.to_time,
    )}`;
  } else if (ohr.request.action === OPENING_HOURS_REQUEST_ACTION.DELETE) {
    return `Eliminar ${getWeekdayName(ohr.weekday)} de ${formatTimeString(ohr.from_time)} a ${formatTimeString(ohr.to_time)}`;
  } else {
    // In an edit ohr.request's weekday, from_time and to_time can be null, in that case we use the original ohr's values
    return `Editar ${getWeekdayName(ohr.weekday)} de ${formatTimeString(ohr.from_time)} a ${formatTimeString(
      ohr.to_time,
    )} a ${getWeekdayName(ohr.request.weekday || ohr.weekday)} de ${formatTimeString(
      ohr.request.from_time || ohr.from_time,
    )} a ${formatTimeString(ohr.request.to_time || ohr.to_time)}`;
  }
};

export default function RejectedOpeningHoursRequestsDialog({ rejectedOpeningHoursRequests, isOpen, close }: Props) {
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={close} fullWidth>
      <DialogTitle>Solicitudes de horarios de retiro rechazadas</DialogTitle>
      <DialogContent>
        {rejectedOpeningHoursRequests.map(
          (ohr) =>
            ohr.request && (
              <Card
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 1, py: 0.5, mb: 1 }}
                variant='outlined'
                key={ohr.id}
              >
                {ohr.request.action === OPENING_HOURS_REQUEST_ACTION.CREATE ? (
                  <Add fontSize='small' />
                ) : ohr.request.action === OPENING_HOURS_REQUEST_ACTION.DELETE ? (
                  <Delete fontSize='small' />
                ) : (
                  <Edit fontSize='small' />
                )}
                <Typography sx={{ px: 1, flex: 1 }}>{getRejectedOpeningHoursRequestText(ohr)}</Typography>
                <Tooltip
                  title={
                    ohr.request.rejected_reason ? `Solicitud rechazada con motivo: ${ohr.request.rejected_reason}` : 'Solicitud rechazada.'
                  }
                  placement='bottom'
                  enterTouchDelay={0}
                >
                  <IconButton size='small' sx={{ color: theme.palette.primary.main }}>
                    <Info fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Card>
            ),
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color='primary'>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
