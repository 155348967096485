import { Box, Grid, Rating, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import EmptyState from 'common/EmptyState';
import { Pagination } from '@mui/material';
import RefreshIndicator from 'common/RefreshIndicator';
import Spinner from 'common/Spinner';
import StoreContext from 'contexts/storeContext';
import { makeStyles } from 'tss-react/mui';
import { Review } from 'types/review';
import ReviewCard from 'components/ReviewCard';

const useStyles = makeStyles()((theme) => ({
  paginationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));

export default function ReviewsScreen() {
  const { classes } = useStyles();
  const { store } = useContext(StoreContext);
  const { api } = useContext(ApiContext);
  const [reviews, setReviews] = useState<Review[]>([]);
  const mobile = useMediaQuery('(max-width: 600px)');
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  // pagination
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    setRefreshing(true);
    api.reviews.list(store.id, { page }).then((response) => {
      setReviews(response.data.results);
      setNumPages(response.data.num_pages);
      setLoading(false);
      setRefreshing(false);
    });
  }, [api, page, store.id]);

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <Box display='flex' alignItems='center' flexWrap='wrap' marginBottom={2}>
        <Typography sx={{ mr: 1 }} variant='h6' noWrap>
          Calificación
        </Typography>
        <Rating value={store.average_rating} sx={{ mr: 1 }} precision={0.1} readOnly />
        <Typography variant='h6' noWrap>
          <Typography variant='h6' sx={{ fontWeight: 'bold', mr: 1 }} display='inline'>
            {store.average_rating.toFixed(1)}
          </Typography>
          ({store.reviews_count} reseñas)
        </Typography>
      </Box>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <RefreshIndicator visible={refreshing} />
          {reviews?.length === 0 ? (
            <EmptyState title='¡Aún no tienes reseñas!' infoLines={['Publica y vende packs para que los usuarios puedan evaluarlos.']} />
          ) : (
            <Grid container spacing={2}>
              {reviews.map((review) => (
                <Grid item key={review.id} xs={12} sm={6} lg={4} xl={3}>
                  <ReviewCard review={review} />
                </Grid>
              ))}
            </Grid>
          )}
          <Box className={classes.paginationContainer}>
            <Pagination
              count={numPages}
              page={page}
              color='primary'
              size={mobile ? 'small' : 'large'}
              siblingCount={0}
              boundaryCount={1}
              onChange={handlePagination}
            />
          </Box>
        </>
      )}
    </>
  );
}
