import { Outlet } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';

import API from 'api';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ApiContext from 'contexts/apiContext';
import { CssBaseline } from '@mui/material';
import ErrorContext, { Error } from 'contexts/errorContext';
import ErrorSnackbar from 'common/ErrorSnackbar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { User } from 'firebase/auth';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import ToastComponent from 'common/Toast';
import ToastContext, { Toast } from 'contexts/toastContext';
import { initializeApp } from 'firebase/app';
import { listenForNotifications } from 'notifications';
import theme from 'theme';
import * as Sentry from '@sentry/react';
import { LocalStorageKeys } from 'localStorageKeys';
import { StatsigProvider } from 'statsig-react';
import { useMountLogs } from 'hooks';
import GlobalContext from 'contexts/globalContext';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const app = initializeApp(config);
const auth = getAuth(app);

const api = new API();

export default function App() {
  const [error, setError] = useState<Error>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [toast, setToast] = useState<Toast>({});
  const [selectedStoreId, setSelectedStoreId] = useState<number>();

  useMountLogs('App');

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        localStorage.setItem(LocalStorageKeys.LOGGED_IN, 'true');
        Sentry.setUser({ id: user.uid, email: user.email || '' });
      } else {
        localStorage.removeItem(LocalStorageKeys.LOGGED_IN);
        Sentry.setUser(null);
      }
    });
    listenForNotifications();
  }, []);

  return (
    <StatsigProvider
      sdkKey={process.env.REACT_APP_STATSIG_SDK_KEY || ''}
      user={{ userID: currentUser?.uid, email: currentUser?.email || undefined }}
      options={{ environment: { tier: process.env.REACT_APP_ENVIRONMENT } }}
      waitForInitialization={true}
      initializingComponent={<></>}
      mountKey='key' // any static value works, we just need it to not change when user changes
    >
      <GlobalContext.Provider value={{ selectedStoreId, setSelectedStoreId }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastContext.Provider value={{ setToast }}>
            <ErrorContext.Provider value={{ setError }}>
              <ApiContext.Provider value={{ auth, api, currentUser }}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Outlet />
                    <ErrorSnackbar error={error} setError={setError} />
                    {!!toast.message && (
                      <ToastComponent
                        open={!!toast.message}
                        message={toast.message}
                        duration={toast.duration}
                        severity={toast.severity}
                        onClose={() => {
                          toast.onClose && toast.onClose();
                          setToast({});
                        }}
                      />
                    )}
                  </ThemeProvider>
                </StyledEngineProvider>
              </ApiContext.Provider>
            </ErrorContext.Provider>
          </ToastContext.Provider>
        </LocalizationProvider>
      </GlobalContext.Provider>
    </StatsigProvider>
  );
}
