import { Box, Card, Dialog, Rating, Typography } from '@mui/material';
import { Review } from 'types/review';
import { makeStyles } from 'tss-react/mui';
import { formatDateTimeString, getDateFromString } from 'utils';
import { useState } from 'react';
import OrderDetail from './OrderDetail';

type Props = {
  review: Review;
};

const useStyles = makeStyles()((theme) => ({
  ratingsContainer: {
    borderColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 5,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  ratingLabel: {
    fontWeight: 600,
    marginTop: theme.spacing(0.5),
  },
  orderCard: {
    paddingBottom: theme.spacing(1),
  },
}));

const MAX_COMMENT_LENGTH = 200;

export default function ReviewCard({ review }: Props) {
  const { classes } = useStyles();
  const [orderDialogOpen, setOrderDialogOpen] = useState(false);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);

  const commentTooLong = review.comment?.length > MAX_COMMENT_LENGTH;
  let comment = review.comment;
  if (commentTooLong) {
    comment = comment?.slice(0, MAX_COMMENT_LENGTH) + '... ';
  }

  return (
    <Card sx={{ height: '100%', p: 1, px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box display='flex'>
        <Typography>Pedido</Typography>
        <Typography
          sx={{ pl: 0.5, fontWeight: 600, cursor: 'pointer', textDecoration: 'underline' }}
          component='span'
          onClick={() => setOrderDialogOpen(true)}
        >
          {review.order.code}
        </Typography>
      </Box>
      <Typography variant='body2'>{formatDateTimeString(getDateFromString(review.created_at))}</Typography>
      <Box className={classes.ratingsContainer}>
        <Typography className={classes.ratingLabel} variant='caption'>
          Calidad de packs
        </Typography>
        <Rating value={review.rating_food_quality} readOnly size='small' />
        <Typography className={classes.ratingLabel} variant='caption'>
          Tamaño de packs
        </Typography>
        <Rating value={review.rating_food_quantity} readOnly size='small' />
        <Typography className={classes.ratingLabel} variant='caption'>
          Experiencia en tienda
        </Typography>
        <Rating value={review.rating_store_experience} readOnly size='small' />
        <Typography className={classes.ratingLabel} variant='caption'>
          Comentario
        </Typography>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='body2' sx={{ px: 1 }}>
            {comment || '-'}
            {commentTooLong && (
              <Typography
                variant='caption'
                sx={{ fontWeight: 600, cursor: 'pointer', textDecoration: 'underline' }}
                component='span'
                onClick={() => setCommentDialogOpen(true)}
                display='inline'
                noWrap
              >
                Ver más
              </Typography>
            )}
          </Typography>
        </Box>
      </Box>
      <Dialog open={orderDialogOpen} onClose={() => setOrderDialogOpen(false)}>
        <Card className={classes.orderCard}>
          <OrderDetail order={review.order} />
        </Card>
      </Dialog>
      <Dialog open={commentDialogOpen} onClose={() => setCommentDialogOpen(false)}>
        <Card sx={{ p: 1 }}>
          <Typography variant='body2'>{review.comment}</Typography>
        </Card>
      </Dialog>
    </Card>
  );
}
