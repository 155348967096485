import { createTheme } from '@mui/material/styles';

const header = {
  fontWeight: 600,
};

const GREEN_BP = '#222B27';

const icon = {
  colorPrimary: {
    color: GREEN_BP,
  },
};

export default createTheme({
  palette: {
    background: {
      default: '#F4F4F4',
    },
    primary: {
      main: '#3C5537',
    },
    secondary: {
      main: '#F9F6E4',
      contrastText: '#354847',
    },
    text: {
      primary: GREEN_BP,
    },
    error: {
      main: '#BB0000',
    },
  },
  components: {
    MuiIconButton: { styleOverrides: icon },
    MuiSvgIcon: { styleOverrides: icon },
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: header,
    h2: header,
    h3: header,
    h4: header,
    h5: header,
    h6: header,
    button: {
      fontWeight: 600,
    },
  },
});
