import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { BasePack, BasePackBeingCreated } from 'types/pack';
import { TaxType } from 'types/taxType';
import { makeStyles } from 'tss-react/mui';
import { useContext, useEffect, useState } from 'react';
import StoreContext from 'contexts/storeContext';
import ImageField from './ImageField';

const useStyles = makeStyles()((theme) => ({
  field: {
    paddingBottom: theme.spacing(2),
  },
  image: {
    height: theme.spacing(8),
    display: 'block',
    margin: theme.spacing(1),
  },
}));

type Props = {
  pack: BasePackBeingCreated;
  packToEdit?: BasePack;
  isOpen: boolean;
  handleChange: (event: any) => void;
  getFieldError: (field: string) => string | undefined;
  onClose: () => void;
  orgImagesOnly?: boolean;
  disabled?: boolean;
};

export default function BasePackDialogFields({
  pack,
  packToEdit,
  isOpen,
  handleChange,
  getFieldError,
  onClose,
  orgImagesOnly,
  disabled,
}: Props) {
  const { store, tags } = useContext(StoreContext);
  const { classes } = useStyles();
  const [taxTypes, setTaxTypes] = useState<TaxType[]>();

  useEffect(() => {
    setTaxTypes(
      store.allowed_tax_types.map((val, i) => {
        return {
          id: val,
          name: store.allowed_tax_types_display[i],
        };
      }),
    );
  }, [store]);

  return (
    <>
      <FormControl className={classes.field} required fullWidth>
        <TextField
          margin='dense'
          id='name'
          name='name'
          label='Nombre'
          value={pack['name']}
          error={!!getFieldError('name')}
          helperText={getFieldError('name')}
          onChange={handleChange}
          type='text'
          required
          disabled={disabled}
        />
      </FormControl>
      <FormControl className={classes.field} required fullWidth>
        <TextField
          margin='dense'
          id='description'
          name='description'
          label='Descripción'
          value={pack['description']}
          error={!!getFieldError('description')}
          helperText={getFieldError('description')}
          onChange={handleChange}
          type='text'
          required
          disabled={disabled}
        />
      </FormControl>
      <FormControl className={classes.field} required fullWidth>
        <TextField
          margin='dense'
          id='original_price'
          name='original_price'
          label='Precio original'
          value={pack['original_price']}
          error={!!getFieldError('original_price')}
          helperText={getFieldError('original_price') || 'Precio habitual de este producto en la tienda, sin descuento.'}
          onChange={handleChange}
          type='number'
          required
          disabled={disabled}
        />
      </FormControl>
      <FormControl className={classes.field} required fullWidth>
        <TextField
          margin='dense'
          id='price'
          name='price'
          label='Precio'
          value={pack['price']}
          error={!!getFieldError('price')}
          helperText={getFieldError('price') || 'Precio de venta en la app.'}
          onChange={handleChange}
          type='number'
          required
          disabled={disabled}
        />
      </FormControl>
      <FormControl className={classes.field} required fullWidth>
        <InputLabel>Tipo de impuesto</InputLabel>
        {taxTypes && (
          <Select name='tax_type' value={pack['tax_type']} onChange={handleChange} label='Tipo de impuesto' disabled={disabled}>
            {taxTypes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <FormControl className={classes.field} key='tags' fullWidth>
        <InputLabel>Tags</InputLabel>
        <Select
          name='tags'
          multiple
          value={pack['tags'] || []}
          renderValue={(selected) =>
            !!tags &&
            tags
              .filter((i) => selected.includes(i.id))
              .map((i) => i.name)
              .join(', ')
          }
          onChange={handleChange}
          label='Tags'
          disabled={disabled}
        >
          {!!tags &&
            tags.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox color='primary' checked={pack['tags']?.includes(option.id)} />
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <ImageField
        isOpen={isOpen}
        initialImageId={packToEdit && packToEdit.image.id}
        onClose={onClose}
        pack={pack}
        handleChange={handleChange}
        error={getFieldError('image')}
        orgImagesOnly={orgImagesOnly}
        disabled={disabled}
      />
    </>
  );
}
