import React, { useEffect, useState } from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Error } from 'contexts/errorContext';

const Alert = React.forwardRef(function Alert(props: AlertProps, ref: React.Ref<any>) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

type Props = {
  error: Error;
  setError: React.Dispatch<React.SetStateAction<Error>>;
};

export default function ErrorSnackbar({ error, setError }: Props) {
  const [open, setOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setOpen(false);
    // wait a bit before setting error to false so the snackbar animation can finish before text changes
    setTimeout(() => setError(false), 200);
  };

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} severity='error' sx={{ width: '100%' }}>
        {error && error !== true ? error.message : 'Ocurrió un error inesperado. Por favor intenta nuevamente en unos minutos.'}
      </Alert>
    </Snackbar>
  );
}
