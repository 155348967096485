import { createContext } from 'react';

export type Error = boolean | { message: string };

type Context = {
  // storeId set by Impersonate screen so that Content can select the store that was opened in the admin
  selectedStoreId?: number;
  setSelectedStoreId: (selectedStoreId?: number) => void;
};

const GlobalContext = createContext<Context>({} as Context);

export default GlobalContext;
