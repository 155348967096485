import * as Sentry from '@sentry/react';

import { Button, FormHelperText, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    marginTop: theme.spacing(2),
    display: 'block',
    width: '100%',
  },
}));

type LoginData = {
  email: string;
  password: string;
};

export default function LoginScreen() {
  const { auth } = useContext(ApiContext);
  const { classes } = useStyles();
  const [loginData, setLoginData] = useState<LoginData>({ email: '', password: '' });
  const [error, setError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);

  const submit = (event: any) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, loginData.email.trim(), loginData.password).catch((error) => {
      if (error.code === 'auth/invalid-email') {
        setError('Email inválido.');
      } else if (error.code === 'auth/missing-email') {
        setError('Email es un campo requerido.');
      } else if (error.code === 'auth/user-disabled') {
        setError('Usuario desactivado.');
      } else if (error.code === 'auth/user-not-found') {
        setError('Usuario no encontrado.');
      } else if (error.code === 'auth/wrong-password') {
        setError('Contraseña incorrecta.');
      } else if (error.code === 'auth/invalid-login-credentials') {
        setError('Credenciales incorrectas. Revisa tu email y contraseña e intenta de nuevo.');
      } else {
        console.error(error);
        Sentry.captureException(error);
        setError('Ocurrió un error inesperado. ¡Intenta de nuevo en unos minutos!');
      }
    });
  };

  const handleChange = (event: any) => {
    setLoginData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <Typography variant='h5' gutterBottom>
        Iniciar sesión
      </Typography>
      <Typography variant='subtitle2' align='center'>
        Completa tus datos para iniciar sesión.
      </Typography>
      <form className={classes.form} noValidate>
        <TextField name='email' label='Email' margin='normal' onChange={handleChange} required fullWidth autoFocus />
        <TextField
          type={showPassword ? 'text' : 'password'}
          name='password'
          label='Contraseña'
          margin='normal'
          onChange={handleChange}
          required
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error && (
          <FormHelperText variant='filled' error={true}>
            {error}
          </FormHelperText>
        )}
        <Button className={classes.submit} type='submit' variant='contained' color='primary' fullWidth onClick={submit}>
          Iniciar sesión
        </Button>
      </form>
      <Link className={classes.link} to={'/auth/reset-password'}>
        <Typography variant='subtitle2'>¿Olvidaste tu contraseña?</Typography>
      </Link>
    </>
  );
}
