import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import CashIcon from '@mui/icons-material/LocalAtm';
import CheckIcon from '@mui/icons-material/Check';
import { ORDER_STATUS } from 'consts';
import { formatDateTimeString, formatPickupDatetimes, getDateFromString } from 'utils';
import { makeStyles } from 'tss-react/mui';
import { Order } from 'types/order';
import { ReviewOrder } from 'types/review';
import theme from 'theme';
import { CalendarMonth, DirectionsWalk, Inbox, Info, Person } from '@mui/icons-material';
import StoreContext from 'contexts/storeContext';
import { useContext } from 'react';

const useStyles = makeStyles()((theme) => ({
  card: {
    paddingBottom: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
  },
  headingItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 0,
  },
  newOrder: {
    boxShadow: '0px 2px 6px 4px #6f9685',
  },
  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  iconAndTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  lineIcon: {
    marginRight: theme.spacing(0.8),
    fontSize: 14,
    marginTop: 1,
  },
  status: {
    fontWeight: 'bold',
  },
  statusContainer: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  quantityColumn: {
    paddingRight: theme.spacing(1),
  },
  unitsColumn: {
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
  },
  semiBold: {
    fontWeight: 600,
  },
  success: {
    color: theme.palette.primary.main,
  },
  bottomItem: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

type Props = {
  order: Order | ReviewOrder;
};

// NOTE: this component should be rendered inside a <Card>
export default function OrderDetail({ order }: Props) {
  const { classes } = useStyles();
  const { formatNumber } = useContext(StoreContext);

  const userDisplayName = 'user' in order && order.user?.display_name;

  return (
    <>
      <CardHeader
        title={
          <>
            <Box className={classes.lineContainer}>
              <Typography className={`${classes.bold} ${classes.headingItem}`}>{order.code}</Typography>
              <Typography className={`${classes.bold} ${classes.headingItem}`}>${formatNumber(order.store_total)}</Typography>
              {'purchase' in order && (
                <Box className={`${classes.lineContainer} ${classes.headingItem}`}>
                  {order.purchase ? <CheckIcon className={classes.lineIcon} /> : <CashIcon className={classes.lineIcon} />}
                  <Typography variant='body2' className={classes.semiBold}>
                    {order.purchase ? 'Pagó a través de la app' : 'Paga en el local'}
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        }
        titleTypographyProps={{ align: 'center' }}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <Table padding='checkbox'>
          <TableHead>
            <TableRow>
              <TableCell align='right' className={`${classes.quantityColumn} ${classes.semiBold}`}>
                Cantidad
              </TableCell>
              <TableCell colSpan={2} className={classes.semiBold}>
                Pack
              </TableCell>
              <TableCell align='right' className={`${classes.unitsColumn} ${classes.semiBold}`}>
                Precio unitario
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='right' className={classes.quantityColumn}>
                  {item.quantity} x
                </TableCell>
                <TableCell colSpan={2}>{item.name}</TableCell>
                <TableCell align='right' className={classes.unitsColumn}>
                  ${formatNumber(item.price)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} align='right' className={classes.unitsColumn}>
                Subtotal: ${formatNumber(order.subtotal)}
              </TableCell>
            </TableRow>
            {order.discount > 0 && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align='right'
                  className={classes.unitsColumn}
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  <Tooltip
                    title={`Un cupón u otro tipo de descuento fue aplicado a este pedido.
Los descuentos son tomados en cuenta al calcular el saldo mensual de tu tienda,
para que recuperes el dinero faltante.
Si tienes dudas, por favor ponte en contacto con nosotros a través de la sección de Ayuda.`}
                    placement='bottom'
                    enterTouchDelay={0}
                  >
                    <IconButton size='small' sx={{ m: 0, mr: 0.5, color: theme.palette.primary.main, padding: 0 }}>
                      <Info fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  Descuento: -${formatNumber(order.discount)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={4} align='right' className={classes.unitsColumn} sx={{ fontWeight: 600 }}>
                Total: ${formatNumber(order.store_total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box className={classes.lineContainer}>
          <Box className={classes.iconAndTextContainer}>
            <Person className={classes.lineIcon} />
            {userDisplayName && <Typography variant='body2'>{userDisplayName}</Typography>}
          </Box>
          <Box className={classes.iconAndTextContainer}>
            <CalendarMonth className={classes.lineIcon} />
            <Typography variant='body2'>{formatDateTimeString(getDateFromString(order.created_at))}</Typography>
          </Box>
          {order.status !== ORDER_STATUS.COMPLETED && (
            <Box className={classes.iconAndTextContainer}>
              <DirectionsWalk className={classes.lineIcon} />
              <Typography variant='body2'>Retiro {formatPickupDatetimes(order.pickup_datetimes)}</Typography>
            </Box>
          )}
        </Box>
        {order.is_byobox && order.status === ORDER_STATUS.PENDING && (
          <Box className={classes.iconAndTextContainer}>
            <Inbox className={classes.lineIcon} />
            <Typography variant='caption' className={classes.semiBold}>
              Retira con su propio recipiente
            </Typography>
          </Box>
        )}
      </CardContent>
    </>
  );
}
