import * as Sentry from '@sentry/react';

import { APIArrayError, APIError, FieldsAPIError } from 'errors';

import axios, { AxiosInstance } from 'axios';
import { getAuth } from '@firebase/auth';
import { OrganizationPack, OrganizationPackBeingCreated, Pack, PackBeingCreated } from 'types/pack';
import { StoreImage } from 'types/storeImage';
import { Pagination } from 'types/pagination';
import { Tag } from 'types/tag';
import { NotificationToken } from 'types/notificationToken';
import { EmployeeStoreAPIResult } from 'types/employeeStore';
import { Review } from 'types/review';
import { OpeningHoursRequest, OpeningHoursRequestBeingCreated } from 'types/openingHours';
import { ClosedDate, ClosedDateDateRange } from 'types/closedDate';
import { TermsAndConditionsApproval } from 'types/termsAndConditions';
import { SalesReport } from 'types/salesReport';
import { Notification } from 'types/notification';

const GENERIC_SERVER_ERROR =
  'Error comunicándose con el servidor. Por favor intenta de nuevo en unos minutos, o ponte en contacto con nosotros a través de la sección de Ayuda.';

class BaseAPI {
  instance: AxiosInstance;
  model: string;
  parentModel?: string;
  nested: boolean;

  constructor(instance: AxiosInstance, model: string, parentModel?: string) {
    this.instance = instance;
    this.model = model;
    this.parentModel = parentModel;
    this.nested = !!parentModel;
  }

  basePath(parentId?: number) {
    return this.nested ? `api/${this.parentModel}/${parentId}/${this.model}/` : `api/${this.model}/`;
  }
}

class ListAPI<T> extends BaseAPI {
  async list(parentId?: number, params: object = {}) {
    return this.instance.get<Pagination<T>>(this.basePath(parentId), { params });
  }
}

class CreateAPI<T> extends BaseAPI {
  async create(data: T, parentId?: number) {
    return this.instance.post(this.basePath(parentId), data);
  }
}

class NonPaginatedListAPI<T> extends ListAPI<T> {
  async list_not_paginated(parentId?: number, params: object = {}) {
    return this.instance.get<T[]>(this.basePath(parentId), { params });
  }
}

class CrudAPI<CreateType, ListType> extends NonPaginatedListAPI<ListType> {
  async get(id: number, parentId?: number) {
    const basePath = this.basePath(parentId);
    return this.instance.get(`${basePath}${id}/`);
  }

  async create(data: CreateType, parentId?: number) {
    return this.instance.post(this.basePath(parentId), data);
  }

  async edit(id: number, data: CreateType, parentId?: number) {
    const basePath = this.basePath(parentId);
    return this.instance.patch(`${basePath}${id}/`, data);
  }

  async delete(id: number, parentId?: number) {
    const basePath = this.basePath(parentId);
    return this.instance.delete(`${basePath}${id}/`);
  }
}

class PacksAPI extends CrudAPI<PackBeingCreated, Pack> {
  constructor(instance: AxiosInstance) {
    super(instance, 'packs', 'stores');
  }

  async duplicate(pack: Pack, storeId: number) {
    const basePath = this.basePath(storeId);
    return this.instance.post(`${basePath}${pack.id}/duplicate/`);
  }

  async quantity(packId: number, storeId: number, oldQuantity: number, quantity: number) {
    const basePath = this.basePath(storeId);
    return this.instance.post(`${basePath}${packId}/quantity/`, { quantity, old_quantity: oldQuantity });
  }

  async cloneFromOrg(orgPack: OrganizationPack, storeId: number) {
    const basePath = this.basePath(storeId);
    return this.instance.post<Pack>(`${basePath}clone-from-org/`, { id: orgPack.id });
  }

  async cloneAllFromOrg(storeId: number) {
    const basePath = this.basePath(storeId);
    return this.instance.post<Pack[]>(`${basePath}clone-all-from-org/`);
  }

  async unlink(pack: Pack, storeId: number) {
    const basePath = this.basePath(storeId);
    return this.instance.post(`${basePath}${pack.id}/unlink/`);
  }
}

class ClosedDatesAPI extends NonPaginatedListAPI<ClosedDate> {
  constructor(instance: AxiosInstance) {
    super(instance, 'closeddates', 'stores');
  }

  async bulkCreate(range: ClosedDateDateRange, storeId: number) {
    const basePath = this.basePath(storeId);
    return this.instance.post(`${basePath}bulk_create/`, range);
  }

  async bulkDelete(range: ClosedDateDateRange, storeId: number) {
    const basePath = this.basePath(storeId);
    return this.instance.delete(`${basePath}bulk_delete/`, { data: range });
  }
}

class StoreImagesAPI extends CrudAPI<StoreImage, StoreImage> {
  constructor(instance: AxiosInstance) {
    super(instance, 'images', 'stores');
  }

  async bulkCreate(images: Blob[], storeId: number, isForOrg: boolean = false) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    Array.from(images).forEach((image) => {
      formData.append('images', image);
    });
    if (isForOrg) {
      // multipart/form-data doesn't support bool, backend checks for exact string 'true'
      formData.append('is_for_organization', 'true');
    }
    return this.instance.post(this.basePath(storeId), formData, { headers });
  }
}

class OpeningHoursRequestsAPI extends CrudAPI<OpeningHoursRequestBeingCreated, OpeningHoursRequest> {
  constructor(instance: AxiosInstance) {
    super(instance, 'openinghoursrequests', 'stores');
  }
}

class NotificationTokensAPI {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getPath(storeId: number) {
    return `api/stores/${storeId}/notificationtokens/`;
  }

  async create(storeId: number, token: string) {
    return this.instance.post<NotificationToken>(this.getPath(storeId), { token });
  }

  async delete(storeId: number, tokenId: string) {
    return this.instance.delete(`${this.getPath(storeId)}${tokenId}/`);
  }
}

class OrdersAPI {
  instance: AxiosInstance;
  basePath: string = 'api/stores/';

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  buildStatusesQueryString(statuses: string[]) {
    let statusString = '';
    statuses.forEach((status) => {
      statusString += '&status=' + status;
    });
    return statusString;
  }

  async list(
    storeId: number,
    pageNumber: number = 1,
    pageSize: number = 20,
    statuses: string[] = [],
    createdAfter?: string,
    createdBefore?: string,
  ) {
    const statusString = this.buildStatusesQueryString(statuses);
    const url = storeId ? `${this.basePath}${storeId}/orders/` : 'api/web/orders/';
    return this.instance.get(`${url}?${statusString}`, {
      params: { page_size: pageSize, page: pageNumber, created_after: createdAfter, created_before: createdBefore },
    });
  }

  async export(storeId: number, extension: string, statuses: string[] = [], createdAfter: string, createdBefore: string) {
    const statusString = this.buildStatusesQueryString(statuses);
    return this.instance.get(`${this.basePath}${storeId}/orders/export/?${statusString}`, {
      params: { extension: extension, created_after: createdAfter, created_before: createdBefore },
      responseType: 'arraybuffer',
    });
  }

  getOrderPath(storeId: number, orderId: number) {
    return `${this.basePath}${storeId}/orders/${orderId}/`;
  }

  async complete(storeId: number, orderId: number) {
    return this.instance.post(`${this.getOrderPath(storeId, orderId)}complete/`);
  }

  async expire(storeId: number, orderId: number) {
    return this.instance.post(`api/stores/${storeId}/orders/${orderId}/expire/`);
  }
}

class EmployeeStoresAPI {
  instance: AxiosInstance;
  basePath: string = 'api/employees/';

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async list() {
    return this.instance.get<EmployeeStoreAPIResult[]>(`${this.basePath}stores/`);
  }

  async select(storeId: number) {
    return this.instance.post(`${this.basePath}select/`, { store_id: storeId });
  }
}

class MercadoPagoAPI {
  instance: AxiosInstance;
  basePath: string = 'api/mercadopago/';

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getOAuthLink(storeId: number) {
    return this.instance.get(`${this.basePath}oauth/${storeId}/link/`);
  }
}

class NotificationsAPI extends ListAPI<Notification> {
  constructor(instance: AxiosInstance) {
    super(instance, 'notifications', 'stores');
  }

  async unreadCount(storeId: number) {
    return this.instance.get<{ unread_count: number }>(`${this.basePath(storeId)}unread_count/`);
  }

  async bulkRead(storeId: number, ids: number[]) {
    return this.instance.post(`${this.basePath(storeId)}bulk_read/`, { instance_ids: ids });
  }
}

export default class API {
  instance: AxiosInstance;

  // APIs
  notificationTokens: NotificationTokensAPI;
  packs: PacksAPI;
  tags: NonPaginatedListAPI<Tag>;
  storeImages: StoreImagesAPI;
  orders: OrdersAPI;
  employeeStores: EmployeeStoresAPI;
  mercadoPago: MercadoPagoAPI;
  reviews: ListAPI<Review>;
  openingHoursRequests: OpeningHoursRequestsAPI;
  closedDates: ClosedDatesAPI;
  organizationPacks: CrudAPI<OrganizationPackBeingCreated, OrganizationPack>;
  termsAndConditionsApprovals: CreateAPI<TermsAndConditionsApproval>;
  salesReport: CreateAPI<SalesReport>;
  notifications: NotificationsAPI;

  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_BACKEND_HTTP_PROTOCOL}://${process.env.REACT_APP_BACKEND_URL}`,
      headers: { 'Content-Type': 'application/json', 'Accept-Language': 'es' },
    });
    this.instance.interceptors.request.use(async (config) => {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const token = await currentUser.getIdToken();
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    });
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          if (error.response.status >= 500) {
            Sentry.captureException(error);
            throw new Error(GENERIC_SERVER_ERROR);
          }
          if (error.response.status === 404) {
            throw new Error(GENERIC_SERVER_ERROR);
          }
          let data = error.response.data;
          if (data instanceof ArrayBuffer) {
            data = JSON.parse(String.fromCharCode.apply(null, Array.from(new Uint8Array(data))));
          }
          if (data.detail || data.non_field_errors) {
            throw new APIError(data.detail || data.non_field_errors);
          } else if (Array.isArray(data)) {
            throw new APIArrayError(data);
          } else {
            throw new FieldsAPIError(data);
          }
        } else {
          Sentry.captureException(error);
          throw new Error(GENERIC_SERVER_ERROR);
        }
      },
    );
    this.notificationTokens = new NotificationTokensAPI(this.instance);
    this.packs = new PacksAPI(this.instance);
    this.tags = new NonPaginatedListAPI(this.instance, 'tags', 'stores');
    this.storeImages = new StoreImagesAPI(this.instance);
    this.orders = new OrdersAPI(this.instance);
    this.employeeStores = new EmployeeStoresAPI(this.instance);
    this.mercadoPago = new MercadoPagoAPI(this.instance);
    this.reviews = new ListAPI(this.instance, 'reviews', 'stores');
    this.openingHoursRequests = new OpeningHoursRequestsAPI(this.instance);
    this.closedDates = new ClosedDatesAPI(this.instance);
    this.organizationPacks = new CrudAPI(this.instance, 'packs', 'organizations');
    this.termsAndConditionsApprovals = new CreateAPI(this.instance, 'termsandconditionsapprovals', 'stores');
    this.salesReport = new CreateAPI(this.instance, 'sales-report', 'stores');
    this.notifications = new NotificationsAPI(this.instance);
  }
}
