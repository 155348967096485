import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { APIError, FieldErrors, FieldsAPIError } from 'errors';
import StoreContext from 'contexts/storeContext';
import ApiContext from 'contexts/apiContext';
import ErrorContext from 'contexts/errorContext';
import { useContext, useState } from 'react';
import { dateToBackendDateString } from 'utils';
import ToastContext from 'contexts/toastContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function SalesReportDialog({ isOpen, onClose }: Props) {
  const { store } = useContext(StoreContext);
  const { api } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const { setToast } = useContext(ToastContext);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
  const [loading, setLoading] = useState(false);

  const getFieldError = (field: string) => fieldErrors && fieldErrors[field];

  const handleError = (err?: APIError) => {
    if (err) {
      if (err instanceof FieldsAPIError) setFieldErrors(err.fieldErrors);
      else setError(err);
    } else {
      setFieldErrors(undefined);
      setError(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleError();
    setLoading(true);
    api.salesReport
      .create({ end_date: dateToBackendDateString(selectedDate || new Date()) }, store.id)
      .then(() => {
        setToast({
          message: '¡El resumen de ventas se está generando! Llegará a tu correo en unos minutos.',
          severity: 'success',
          duration: 5000,
        });
        onClose();
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle>Resumen de ventas</DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent sx={{ py: 0 }}>
          <Typography>Se generará un resumen de ventas con los pedidos desde el primer día del año hasta el día seleccionado.</Typography>
          <Typography>El resumen generado será enviado a tu correo electrónico.</Typography>
          <FormControl>
            <DatePicker
              name='end_date'
              format='dd/MM/yyyy'
              label='Fecha de fin'
              value={selectedDate}
              onChange={setSelectedDate}
              sx={{ mt: 3 }}
            />
            {!!getFieldError('end_date') && (
              <FormHelperText variant='filled' error={true}>
                {getFieldError('end_date')}
              </FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color='error'>
            Cancelar
          </Button>
          <Button disabled={loading} onClick={handleSubmit} color='primary'>
            Generar y enviar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
