import * as Sentry from '@sentry/react';

import { useContext, useEffect, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import Spinner from 'common/Spinner';
import ToastContext from 'contexts/toastContext';
import { Typography } from '@mui/material';
import { signInWithCustomToken } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import GlobalContext from 'contexts/globalContext';

export default function ImpersonateScreen() {
  const { token, storeId } = useParams();
  const { auth } = useContext(ApiContext);
  const [showError, setShowError] = useState(false);
  const { setToast } = useContext(ToastContext);
  const { setSelectedStoreId } = useContext(GlobalContext);

  useEffect(() => {
    if (storeId) {
      setSelectedStoreId(Number(storeId));
    }
    token &&
      signInWithCustomToken(auth, token)
        .then((creds) => {
          const username = creds.user.displayName || creds.user.email;
          setToast({
            message: `Iniciaste sesión como ${username}`,
            severity: 'info',
          });
        })
        .catch((err) => {
          setShowError(true);
          console.error(err);
          Sentry.captureException(err);
        });
  }, [auth, setToast, token, storeId, setSelectedStoreId]);

  return showError ? <Typography>Token inválido.</Typography> : <Spinner />;
}
