export enum ORDER_STATUS {
  PENDING = 'PEN',
  COMPLETED = 'COMP',
  CANCELLED = 'CANC',
  EXPIRED = 'EXP',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
}

export const ORDER_STATUS_DISPLAY = {
  PEN: 'Pendiente',
  COMP: 'Completado',
  CANC: 'Cancelado',
  EXP: 'Expirado',
  NOT_CONFIRMED: 'Retiro no confirmado',
};

export const WEEKDAYS = [
  { value: 1, name: 'Lunes' },
  { value: 2, name: 'Martes' },
  { value: 3, name: 'Miércoles' },
  { value: 4, name: 'Jueves' },
  { value: 5, name: 'Viernes' },
  { value: 6, name: 'Sábado' },
  { value: 7, name: 'Domingo' },
];

export enum PaymentGateway {
  MERCADOPAGO = 'MERCADOPAGO',
  PLEXO = 'PLEXO',
}

export const PERMISSIONS = {
  EDIT_PACK: 'edit_pack',
  CONNECT_MERCADOPAGO: 'connect_mercadopago',
  MANAGE_IMAGES: 'manage_images',
};

export const STORE_IMAGE_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const OPENING_HOURS_REQUEST_ACTION = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

export enum OPENING_HOURS_REQUEST_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum STATSIG_FLAGS {
  CONNECT_MP_UY_ENABLED = 'connect_mp_uy_enabled',
}

export enum CountryCode {
  URUGUAY = 'UY',
  COLOMBIA = 'CO',
  ARGENTINA = 'AR',
  PARAUGUAY = 'PY',
}
