import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { ORDER_STATUS } from 'consts';
import OrderList from 'components/OrderList';
import { useCallback, useContext, useEffect, useState } from 'react';
import NotificationsContext from 'contexts/notificationsContext';
import OrdersContext from 'contexts/ordersContext';
import { Refresh } from '@mui/icons-material';
import { useMountLogs } from 'hooks';

export default function Orders() {
  const { orderNotification, clearOrderNotification } = useContext(NotificationsContext);
  const [shouldRefreshOrders, setShouldRefreshOrders] = useState(true);
  const [highlightOrderId, setHighlightOrderId] = useState<number>();
  const [daysBack, setDaysBack] = useState(2);

  useMountLogs('OrdersScreen');

  const refreshOrders = useCallback(() => {
    setShouldRefreshOrders((prev) => !prev);
  }, []);

  // Highlight new order if it's in the URL
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const newOrderId = Number(query.get('newOrderId')); // Returns NaN (falsy) if newOrderId can't be parsed
    if (newOrderId) {
      setHighlightOrderId(newOrderId);
    }
  }, []);

  // Refresh orders and highlight new one when an order notification is received
  useEffect(() => {
    if (orderNotification) {
      refreshOrders();
      setHighlightOrderId(orderNotification.order_id);
      clearOrderNotification();
    }
  }, [orderNotification, refreshOrders, clearOrderNotification]);

  return (
    <OrdersContext.Provider value={{ shouldRefreshOrders, refreshOrders, highlightOrderId }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <Button variant='contained' startIcon={<Refresh />} onClick={refreshOrders} sx={{ mr: 2 }}>
          Refrescar
        </Button>
        <FormControl sx={{ minWidth: 100 }} size='small'>
          <InputLabel>Días atrás</InputLabel>
          <Select value={daysBack} onChange={(e) => setDaysBack(e.target.value as number)} label='Días atrás'>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={7}>7</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={8}>
        <OrderList title='Pendientes' statuses={[ORDER_STATUS.PENDING]} daysBack={daysBack} />
        <OrderList
          title='Finalizados'
          statuses={[ORDER_STATUS.COMPLETED, ORDER_STATUS.NOT_CONFIRMED, ORDER_STATUS.CANCELLED, ORDER_STATUS.EXPIRED]}
          daysBack={daysBack}
        />
      </Grid>
    </OrdersContext.Provider>
  );
}
