import { Card, CardMedia, Container, Link, Typography } from '@mui/material';
import ApiContext from 'contexts/apiContext';
import { router } from 'index';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import banner from 'static/banner.png';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  media: {
    height: '14vh',
    objectFit: 'contain',
  },
}));

export default function Auth() {
  const { currentUser } = useContext(ApiContext);
  const { classes } = useStyles();

  useEffect(() => {
    if (currentUser) {
      router.navigate('/app');
    }
  }, [currentUser]);

  return (
    <Container className={classes.container} maxWidth='sm'>
      <CardMedia component='img' className={classes.media} image={banner} title='Logo Buen Provecho' />
      <Typography variant='subtitle2' align='center' sx={{ mt: 4 }}>
        ¡Bienvenid@ a la aplicación para tiendas de Buen Provecho! 🫶🏼
      </Typography>
      <Typography variant='subtitle2' align='center'>
        Si quieres adherir tu tienda a la plataforma, llena el{' '}
        <Link href='https://www.buenprovechoapp.com/#registra-tu-tienda' target='_blank'>
          formulario de registro
        </Link>
        .
      </Typography>
      <Card className={classes.card}>
        <Outlet />
      </Card>
    </Container>
  );
}
