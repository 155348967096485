import { WEEKDAYS } from 'consts';
import { DatedOpeningHour } from 'types/openingHours';
import { PickupDatetime } from 'types/order';

// Returns a two-digit string of a number (converting 1 to '01' for example) to show in a clock
export const forceTwoDigits = (n: number): string => {
  return n < 10 ? `0${n}` : n.toString();
};

export const DEVICE_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Creates a Date from a date string in backend's datetime format
// E.g.: '2024-04-22T19:00:00Z' (UTC) and '2024-04-22T12:00:00-03:00' (localized)
export const getDateFromString = (dateString: string): Date => {
  return new Date(dateString);
};

// Returns date as a string in the format "dd/mm/yy"
// Pass ignore_tz=true to ignore the timezone and always return the received naive date
// Else the string '2024-08-20' will be converted to '19/08/24' in America/Montevideo because
// the date is understood as '2024-08-20 00:00:00 UTC, which is the 19th in Montevideo
export const getDateString = (date: Date, ignore_tz: boolean = false): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    timeZone: ignore_tz ? 'UTC' : DEVICE_TIMEZONE,
  };
  return date.toLocaleDateString('es-ES', options);
};

// Returns string in format YYYY-MM-DD
export const dateToBackendDateString = (date: Date) => {
  return date.getFullYear() + '-' + forceTwoDigits(date.getMonth() + 1) + '-' + forceTwoDigits(date.getDate());
};

// Returns a string in format HH:mm:ss or HH:mm depending on `seconds`
export const getTimeString = (date: Date, seconds: boolean = false): string => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: DEVICE_TIMEZONE,
  };
  // Android displays the seconds as well for some reason, so we need to truncate it
  return date.toLocaleTimeString([], options).substring(0, seconds ? 8 : 5);
};

// Creates a date with today's date and the time from a time string in format '12:00:00'
export const getDateFromTimeString = (timeString?: string): Date | undefined => {
  if (!timeString) return undefined;
  const [hours, minutes, seconds] = timeString.split(':');
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  date.setSeconds(parseInt(seconds, 10));
  return date;
};

// Compares dates without the time
// .toDateString() brings problems if not running it in UTC and times are different
// Note: 2023-01-02T12:00:00 in UTC and 2023-01-01T23:00:00-03:00 will return FALSE even though
// they're the same date in UTC, but not locally. this is on purpose.
export const dateEquals = (d1: Date, d2: Date): boolean => {
  return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
};

// Returns a tuple with whether the given date is today, whether it is yesterday, and whether it is tomorrow
const isTodayOrYesterdayOrTomorrow = (date: Date): [boolean, boolean, boolean] => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return [dateEquals(date, today), dateEquals(date, yesterday), dateEquals(date, tomorrow)];
};

// Returns a string in format DD/MM/YY - HH:mm
export const getDateAndTimeString = (date: Date): string => {
  const dateString = getDateString(date);
  const timeString = getTimeString(date);
  return `${dateString} ${timeString}`;
};

// Returns a readable string in format [Hoy|Ayer|DD/MM/YY] HH:mm from a string in backend's datetime format
export const formatDateTimeString = (date: Date) => {
  const [isToday, isYesterday] = isTodayOrYesterdayOrTomorrow(date);
  const day = isToday ? 'Hoy' : isYesterday ? 'Ayer' : getDateString(date);
  return day + ' ' + getTimeString(date);
};

// Returns a readable string in format [hoy|ayer|mañana|el DD/MM/YY] from a string in format YYYY-MM-DD
export const formatDateString = (date: Date) => {
  const [isToday, isYesterday, isTomorrow] = isTodayOrYesterdayOrTomorrow(date);
  if (isToday) return 'hoy';
  if (isYesterday) return 'ayer';
  if (isTomorrow) return 'mañana';
  return 'el ' + getDateString(date);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getWeekdayName = (value?: number) => {
  if (!value) return '-';
  return WEEKDAYS.find((weekday) => weekday.value === value)?.name.toLowerCase() || '-';
};

// Returns whether the given DatedOpeningHour is current (i.e. started before now, ends after now)
export const isCurrentOpeningHour = (doh?: DatedOpeningHour): boolean => {
  if (!doh) return false;
  const now = new Date();
  const openingTime = getDateFromString(doh.from_datetime_utc);
  const closingTime = getDateFromString(doh.to_datetime_utc);
  return openingTime <= now && now <= closingTime;
};

// Returns a string in format HH:mm - HH:mm
export const formatPickupTime = (doh: DatedOpeningHour | PickupDatetime): string => {
  return getTimeString(getDateFromString(doh.from_datetime_utc)) + ' - ' + getTimeString(getDateFromString(doh.to_datetime_utc));
};

// Returns a string in format: Retiro [day]: from_time - to_time, from_time - to_time, ...
// where day is in the format of formatDateString and from/to times are in the format of getTimeString
export const formatPickupDatetimes = (dohs: PickupDatetime[] | DatedOpeningHour[]): string => {
  return `${formatDateString(getDateFromString(dohs[0].from_datetime_utc))}: ${dohs.map(formatPickupTime).join(', ')}`;
};

// Returns a string in format HH:mm from a string in format HH:mm:ss
// Should only be used in special cases. Try to use getTimeString instead passing in an actual Date.
export const formatTimeString = (timeString?: string) => {
  if (!timeString) return '-';
  return timeString.slice(0, 5);
};
