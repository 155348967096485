import { Box, Button, Card, CardActions, Typography } from '@mui/material';
import { useContext, useEffect, useState, useRef } from 'react';

import ApiContext from 'contexts/apiContext';
import DoneIcon from '@mui/icons-material/Done';
import { ORDER_STATUS } from 'consts';
import { makeStyles } from 'tss-react/mui';
import { Order } from 'types/order';
import { ReviewOrder } from 'types/review';
import StoreContext from 'contexts/storeContext';
import OrderDetail from './OrderDetail';
import OrdersContext from 'contexts/ordersContext';
import { AccessTimeFilled, Cancel, CheckCircle, Help } from '@mui/icons-material';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles()((theme) => ({
  card: {
    paddingBottom: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
  },
  headingItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 0,
  },
  name: {
    marginTop: theme.spacing(1),
    fontWeight: 600,
  },
  time: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  newOrder: {
    boxShadow: '0px 2px 6px 4px #6f9685',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(0.5),
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  byoboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center ',
    marginTop: theme.spacing(1),
  },
  lineIcon: {
    marginRight: theme.spacing(0.8),
    fontSize: 14,
    marginTop: 1,
  },
  status: {
    fontWeight: 600,
  },
  statusContainer: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  quantityColumn: {
    paddingRight: theme.spacing(1),
  },
  unitsColumn: {
    whiteSpace: 'nowrap',
  },
  semiBold: {
    fontWeight: 600,
  },
  success: {
    color: theme.palette.primary.main,
  },
  margin: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  order: Order | ReviewOrder;
};

const HIGHLIGHT_INTERVAL = 250;

export default function OrderCard({ order }: Props) {
  const { api } = useContext(ApiContext);
  const { store } = useContext(StoreContext);
  const { refreshOrders, highlightOrderId } = useContext(OrdersContext);

  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const [isNewOrder, setIsNewOrder] = useState(false);

  const highlightInterval = useRef<ReturnType<typeof setInterval>>();
  const highlightTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (highlightOrderId === order.id) {
      highlightInterval.current = setInterval(() => setIsNewOrder((state) => !state), HIGHLIGHT_INTERVAL);
      highlightTimeout.current = setTimeout(() => {
        if (highlightInterval.current) {
          clearInterval(highlightInterval.current);
        }
        setIsNewOrder(false);
      }, HIGHLIGHT_INTERVAL * 6); // Highlight and unhighlight 3 times
    }
    return () => {
      highlightInterval.current && clearInterval(highlightInterval.current);
      highlightTimeout.current && clearTimeout(highlightTimeout.current);
    };
  }, [highlightOrderId, order.id]);

  const complete = () => {
    if (window.confirm('¿Completar pedido?')) {
      setLoading(true);
      api.orders
        .complete(store.id, order.id)
        .then(refreshOrders)
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        })
        .finally(() => setLoading(false));
    }
  };

  const expire = () => {
    if (
      window.confirm(
        '¿Expirar pedido?\n\nPuedes marcar este pedido como expirado si el usuario no retiró sus packs. Podrás completar el pedido luego si el usuario viene fuera del horario de retiro y aún tienes los packs para entregarle.',
      )
    ) {
      setLoading(true);
      api.orders
        .expire(store.id, order.id)
        .then(refreshOrders)
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Card className={cx(classes.card, isNewOrder && classes.newOrder)}>
      <OrderDetail order={order} />
      <CardActions className={classes.cardActions}>
        <Box className={classes.columnContainer}>
          {order.status !== ORDER_STATUS.PENDING && (
            <Box className={`${classes.rowContainer} ${classes.statusContainer} ${classes.margin}`}>
              {order.status === ORDER_STATUS.COMPLETED && <CheckCircle className={`${classes.lineIcon} ${classes.success}`} />}
              {order.status === ORDER_STATUS.CANCELLED && <Cancel className={classes.lineIcon} color='error' />}
              {order.status === ORDER_STATUS.EXPIRED && <AccessTimeFilled className={classes.lineIcon} />}
              {order.status === ORDER_STATUS.NOT_CONFIRMED && <Help className={classes.lineIcon} />}
              <Typography variant='body2' className={classes.status}>
                {order.status === ORDER_STATUS.COMPLETED && !order.completed_by_store ? 'Completado por el usuario' : order.status_display}
              </Typography>
            </Box>
          )}
          <Box className={classes.rowContainer}>
            {order.status === ORDER_STATUS.NOT_CONFIRMED && (
              <Button onClick={expire} variant='outlined' size='small' className={classes.margin}>
                El usuario no retiró sus packs
              </Button>
            )}
            {order.status === ORDER_STATUS.EXPIRED && (
              <Button onClick={complete} variant='outlined' size='small' className={classes.margin}>
                El usuario retiró sus packs
              </Button>
            )}
            {[ORDER_STATUS.PENDING, ORDER_STATUS.COMPLETED, ORDER_STATUS.NOT_CONFIRMED].includes(order.status) &&
              !order.completed_by_store && (
                <Button
                  startIcon={<DoneIcon />}
                  color='primary'
                  variant='contained'
                  onClick={complete}
                  disabled={loading}
                  className={classes.margin}
                  size='small'
                >
                  Completar
                </Button>
              )}
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
}
