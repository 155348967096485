import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVertSharp';
import PermissionTooltip from './PermissionTooltip';
import { useState } from 'react';

type Props = {
  options: {
    name: string;
    action: () => void;
    disabled?: boolean;
  }[];
  disabled?: boolean;
};

export default function VerticalMenu({ options, disabled }: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (optionAction: () => void) => {
    handleClose();
    optionAction();
  };

  return (
    <>
      <IconButton
        title='Más opciones'
        color='primary'
        size='small'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleOpenClick}
        disabled={disabled}
      >
        <MoreVertIcon fontSize='small' />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {options.map((option) => (
          <PermissionTooltip hasPermission={!option.disabled} key={option.name}>
            <MenuItem onClick={() => handleClick(option.action)} disabled={!!option.disabled}>
              {option.name}
            </MenuItem>
          </PermissionTooltip>
        ))}
      </Menu>
    </>
  );
}
