import { useContext, useEffect, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import { Box } from '@mui/material';
import ErrorContext from 'contexts/errorContext';
import ImageSelect from 'components/ImageSelect';
import { Pagination } from '@mui/material';
import { STORE_IMAGE_STATUS } from 'consts';
import StoreContext from 'contexts/storeContext';
import ToastContext from 'contexts/toastContext';
import { makeStyles } from 'tss-react/mui';
import { StoreImage } from 'types/storeImage';
import { PackBeingCreated } from 'types/pack';

const useStyles = makeStyles()((theme) => ({
  field: {
    paddingBottom: theme.spacing(2),
  },
  image: {
    height: theme.spacing(8),
    display: 'block',
    margin: theme.spacing(1),
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  isOpen: boolean;
  initialImageId?: number;
  onClose: () => void;
  pack: PackBeingCreated;
  handleChange: (event: any) => void;
  error?: string;
  orgImagesOnly?: boolean;
  disabled?: boolean;
};

export default function ImageField({ isOpen, initialImageId, onClose, pack, handleChange, error, orgImagesOnly, disabled }: Props) {
  const { classes } = useStyles();
  const { api } = useContext(ApiContext);
  const { store } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);
  const { setToast } = useContext(ToastContext);

  const [storeImages, setStoreImages] = useState<StoreImage[]>([]);
  const [storeImagesPage, setStoreImagesPage] = useState(1);
  const [storeImagesNumPages, setStoreImagesNumPages] = useState(0);

  useEffect(() => {
    if (isOpen && store) {
      api.storeImages
        .list(store.id, {
          selected_image: initialImageId,
          status: STORE_IMAGE_STATUS.APPROVED,
          page: storeImagesPage,
          page_size: 12,
          ...(orgImagesOnly && { org__notnull: true }),
        })
        .then((res) => {
          setStoreImages(res.data.results);
          setStoreImagesNumPages(res.data.num_pages);
          if (res.data.count === 0) {
            setToast({
              message: 'Todavía no tienes imágenes aprobadas. Puedes subirlas en la sección de imágenes.',
              severity: 'warning',
            });
            onClose();
          }
        })
        .catch(setError);
    } else if (!isOpen) {
      setStoreImages([]);
      setStoreImagesPage(1);
      setStoreImagesNumPages(0);
    }
  }, [isOpen, store, api, setError, storeImagesPage, setToast, onClose, initialImageId, orgImagesOnly]);

  return (
    <Box className={classes.field} key='image'>
      <ImageSelect name='image' options={storeImages} value={pack.image} onChange={handleChange} error={error} disabled={disabled} />
      <Box className={classes.paginationContainer}>
        <Pagination
          count={storeImagesNumPages}
          page={storeImagesPage}
          color='primary'
          siblingCount={0}
          boundaryCount={1}
          onChange={(event, page) => setStoreImagesPage(page)}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
}
