import { Box, Button, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { confirmPasswordReset } from 'firebase/auth';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router-dom';
import ErrorContext from 'contexts/errorContext';

const useStyles = makeStyles()((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ResetPasswordConfirmScreen() {
  const { auth } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const query = useQuery();
  const { classes } = useStyles();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [success, setSuccess] = useState(false);

  const submit = (event: any) => {
    event.preventDefault();
    if (password1 !== password2) {
      setError({ message: 'Las contraseñas no coinciden.' });
      return;
    }
    const code = query.get('oobCode');
    if (!code) {
      setError({ message: 'No se ha encontrado el código de recuperación. Por favor intenta de nuevo.' });
      return;
    }
    confirmPasswordReset(auth, code, password1)
      .then(() => setSuccess(true))
      .catch(setError);
  };

  return !!success ? (
    <>
      <Box className={classes.box}>
        <CheckCircleIcon className={classes.icon} fontSize='medium' />
        <Typography variant='h6'>¡Cambiada!</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography align='center'>Ya puedes iniciar sesión con tu nueva contraseña.</Typography>
      </Box>
    </>
  ) : (
    <>
      <Typography variant='h5' gutterBottom>
        Recupera tu cuenta
      </Typography>
      <Typography variant='subtitle2' align='center'>
        Ingresa una nueva contraseña para recuperar el acceso a tu cuenta.
      </Typography>
      <form className={classes.form} noValidate>
        <TextField type='password' label='Contraseña' margin='normal' onChange={(e) => setPassword1(e.target.value)} required fullWidth />
        <TextField
          type='password'
          label='Repetir contraseña'
          margin='normal'
          onChange={(e) => setPassword2(e.target.value)}
          required
          fullWidth
        />
        <Button className={classes.submit} type='submit' variant='contained' color='primary' fullWidth onClick={submit}>
          Enviar
        </Button>
      </form>
    </>
  );
}
