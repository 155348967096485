// Similar component to Spinner, but smaller and with absolute (floating) position.
import { Box, Card, CircularProgress, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  card: {
    height: '36px',
    width: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
  },
}));

type Props = {
  visible: boolean;
};

export default function RefreshIndicator({ visible }: Props) {
  const { classes } = useStyles();
  const theme = useTheme();

  return visible ? (
    <Box position='relative' width={'100%'} zIndex={10}>
      <Box position='absolute' left='50%' top={theme.spacing(3)}>
        {/* Using card for its pretty shadow effect */}
        <Card className={classes.card}>
          <CircularProgress color='primary' size='24px' thickness={4} />
        </Card>
      </Box>
    </Box>
  ) : (
    <></>
  );
}
