import { makeStyles } from 'tss-react/mui';
import { Badge, Box, Card, CardHeader, Link, Typography, useTheme } from '@mui/material';
import { Notification } from 'types/notification';
import { formatDateTimeString, getDateFromString } from 'utils';

const useStyles = makeStyles()((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    display: 'block',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

type Props = {
  notification: Notification;
};

export default function NotificationCard({ notification }: Props) {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <Badge badgeContent={notification.read ? 0 : 1} color='warning' variant='dot' sx={{ width: '100%' }}>
      <Card
        sx={{
          mb: 1.5,
          width: '100%',
          backgroundColor: notification.read ? theme.palette.grey[100] : 'white',
        }}
      >
        <CardHeader
          className={classes.cardHeader}
          title={
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='body2' sx={{ fontWeight: 600, flex: 1, mr: 1 }}>
                {notification.title}
              </Typography>
              <Typography variant='body2' sx={{ fontSize: 12, color: 'gray' }}>
                {formatDateTimeString(getDateFromString(notification.created_at))}
              </Typography>
            </Box>
          }
        />
        <Box padding={1.5} display='flex' flexDirection='column'>
          <Typography variant='body2'>{notification.body || '-'}</Typography>
          {notification.link && (
            <Link href={notification.link} alignSelf={'flex-end'} target='_blank'>
              <Typography variant='body2' sx={{ fontWeight: 600, pt: 0.5 }} alignSelf={'flex-end'}>
                Abrir
              </Typography>
            </Link>
          )}
        </Box>
      </Card>
    </Badge>
  );
}
