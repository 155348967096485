import { Alert, AlertColor } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

type Props = {
  open: boolean;
  onClose: () => void;
  duration?: number;
  message: string;
  severity?: AlertColor;
};

export default function Toast({ open, onClose, duration = 3000, message, severity }: Props) {
  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={onClose}>
      <Alert elevation={6} variant='filled' severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
