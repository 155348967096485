import { Dispatch, SetStateAction, createContext } from 'react';

export type Error = boolean | { message: string };

type Context = {
  setError: Dispatch<SetStateAction<Error>>;
};

const ErrorContext = createContext<Context>({} as Context);

export default ErrorContext;
