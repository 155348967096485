import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import ErrorContext from 'contexts/errorContext';
import { LocalStorageKeys } from 'localStorageKeys';
import StoreContext from 'contexts/storeContext';
import TemporalLabel from 'common/TemporalLabel';
import { WEEKDAYS } from 'consts';
import { makeStyles } from 'tss-react/mui';
import { Tag } from 'types/tag';
import { APIError, FieldErrors, FieldsAPIError } from 'errors';
import { Pack, PackBeingCreated } from 'types/pack';
import BasePackDialogFields from './BasePackDialogFields';

const useStyles = makeStyles()((theme) => ({
  field: {
    paddingBottom: theme.spacing(2),
  },
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  packToEdit?: Pack;
  callback: (pack: PackBeingCreated) => Promise<any>;
};

export default function PackDialog({ isOpen, onClose, onSuccess, packToEdit, callback }: Props) {
  const { store } = useContext(StoreContext);
  const { setError } = useContext(ErrorContext);

  const { classes } = useStyles();
  const [pack, setPack] = useState<PackBeingCreated>({});
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [autoQuantityEnabled, setAutoQuantityEnabled] = useState(false);
  const [showAutoPublishLabel, setShowAutoPublishLabel] = useState(false);

  useEffect(() => {
    if (packToEdit) {
      let packCopy: PackBeingCreated = { ...packToEdit, tags: packToEdit.tags.map((tag: Tag) => tag.id), image: packToEdit.image.id };
      delete packCopy.quantity;
      setPack(packCopy);
      setAutoQuantityEnabled(!!packToEdit.auto_publish_qty);
      setIsEdit(true);
    } else {
      setPack({ tax_type: store.default_tax_type });
      setAutoQuantityEnabled(false);
      setIsEdit(false);
    }
  }, [packToEdit, store]);

  useEffect(() => {
    if (isOpen) {
      if (!localStorage.getItem(LocalStorageKeys.HAS_SEEN_AUTO_PUBLISH)) {
        setShowAutoPublishLabel(true);
        localStorage.setItem(LocalStorageKeys.HAS_SEEN_AUTO_PUBLISH, 'true');
      } else {
        setShowAutoPublishLabel(false);
      }
    }
  }, [isOpen]);

  const handleError = (err?: APIError) => {
    if (err) {
      if (err instanceof FieldsAPIError) setFieldErrors(err.fieldErrors);
      else setError(err);
    } else {
      setFieldErrors(undefined);
      setError(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleError(undefined);
    setLoading(true);
    const result = callback(pack);
    result
      .then(() => handleClose(true))
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const handleClose = (success = false) => {
    if (!isEdit) {
      setPack({});
    }
    handleError(undefined);
    success ? onSuccess() : onClose();
  };

  const handleChange = (event: any) => {
    setPack((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAutoQuantityEnabledChanged = () => {
    const enabled = !autoQuantityEnabled;
    setAutoQuantityEnabled(enabled);
    if (!enabled) {
      // Remove auto quantity and days if user unchecks the "Publish automatically" checkbox so that it gets updated in the backend
      setPack((prevState) => ({
        ...prevState,
        auto_publish_qty: null,
        auto_publish_days: [],
      }));
    } else {
      // Default to publishing all weekdays when user checks the "Publish automatically" checkbox
      setPack((prevState) => ({
        ...prevState,
        auto_publish_days: WEEKDAYS.map((weekday) => weekday.value),
      }));
    }
  };

  const getFieldError = (field: string) => fieldErrors && fieldErrors[field];

  const isLinked = !!packToEdit?.organization_pack;

  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)} fullWidth>
      <DialogTitle>{isEdit ? 'Editar' : 'Crear'} pack</DialogTitle>
      <DialogContent>
        {isLinked && (
          <>
            <Typography sx={{ mb: 1 }}>
              Este pack está vinculado a un pack compartido. Para editar los campos compartidos, edita el pack compartido o desvincúlalo del
              mismo.
            </Typography>
            <Typography sx={{ mb: 2 }}>Puedes editar la publicación automática debajo.</Typography>
          </>
        )}
        <form noValidate onSubmit={handleSubmit}>
          <BasePackDialogFields
            isOpen={isOpen}
            pack={pack}
            packToEdit={packToEdit}
            handleChange={handleChange}
            getFieldError={getFieldError}
            onClose={onClose}
            disabled={isLinked}
          />
          {!isEdit && (
            <FormControl className={classes.field} required fullWidth>
              <TextField
                margin='dense'
                id='quantity'
                name='quantity'
                label='Unidades disponibles'
                value={pack['quantity']}
                error={!!getFieldError('quantity')}
                helperText={getFieldError('quantity')}
                onChange={handleChange}
                type='number'
                required
              />
            </FormControl>
          )}
          <FormControl className={classes.field}>
            <FormControlLabel
              control={
                <Checkbox checked={autoQuantityEnabled} onChange={handleAutoQuantityEnabledChanged} name='checkedB' color='primary' />
              }
              label='Publicación automática'
            />
            <FormHelperText>Elegir días y número de unidades de este pack para publicar automáticamente.</FormHelperText>
            {showAutoPublishLabel && (
              <TemporalLabel text='¡Ahora puedes configurar packs para que se publiquen automáticamente!' delay={1000} />
            )}
          </FormControl>
          {autoQuantityEnabled && (
            <>
              <FormControl className={classes.field} required fullWidth>
                <TextField
                  margin='dense'
                  id='auto_publish_qty'
                  name='auto_publish_qty'
                  label='Cantidad a publicar cada día'
                  value={pack['auto_publish_qty'] || ''}
                  error={!!getFieldError('auto_publish_qty')}
                  helperText={
                    getFieldError('auto_publish_qty') ||
                    'Cantidad de unidades de este pack que se publicarán automáticamente en los días seleccionados.'
                  }
                  onChange={handleChange}
                  type='number'
                />
              </FormControl>
              <FormControl className={classes.field} key='auto_publish_days' fullWidth>
                <InputLabel>Días en que se publica</InputLabel>
                <Select
                  name='auto_publish_days'
                  multiple
                  value={pack['auto_publish_days'] || []}
                  renderValue={(selected) =>
                    WEEKDAYS.filter((weekday) => selected.includes(weekday.value))
                      .map((weekday) => weekday.name)
                      .join(', ')
                  }
                  onChange={handleChange}
                  label='Días en que se publica'
                >
                  {WEEKDAYS.map((weekday) => (
                    <MenuItem key={weekday.value} value={weekday.value}>
                      <Checkbox color='primary' checked={pack['auto_publish_days']?.includes(weekday.value)} />
                      {weekday.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  No se publicarán unidades los días en los que la tienda no está abierta en Buen Provecho, aunque hayan sido seleccionados.
                  Las unidades se publican el día anterior al cerrar la tienda, para que estén diponibles durante la totalidad de cada día
                  seleccionado.
                </FormHelperText>
              </FormControl>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color='primary'>
          Cancelar
        </Button>
        <Button disabled={loading} variant='contained' onClick={handleSubmit} color='primary'>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
