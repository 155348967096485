import { Box, Button, Card, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRouteError } from 'react-router-dom';

export default function Error() {
  let error = useRouteError();

  useEffect(() => {
    console.error(error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <Box sx={{ w: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
      <Card sx={{ maxWidth: 500, p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 600, fontSize: 16, mb: 1, textAlign: 'center' }} variant='h6'>
          ¡Ups! Algo salió mal.
        </Typography>
        <Typography sx={{ mb: 0.5, textAlign: 'center' }} variant='body2'>
          Ya estamos al tanto del problema y trabajando para solucionarlo.
        </Typography>
        <Typography sx={{ textAlign: 'center', mb: 2 }} variant='body2'>
          Por favor intenta de nuevo en unos minutos, o si el problema persiste, ponte en contacto con un administrador de la plataforma.
        </Typography>
        <Link to='/'>
          <Button variant='contained' color='primary' size='small'>
            Volver al inicio
          </Button>
        </Link>
      </Card>
    </Box>
  );
}
