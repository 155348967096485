import { Card, CardMedia, FormHelperText, Grid, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  image: {
    height: '100%',
    objectFit: 'cover',
  },
  card: {
    margin: '3px',
    aspectRatio: 1,
  },
  pointer: {
    cursor: 'pointer',
  },
  selectedCard: {
    margin: 0,
    border: `solid 3px ${theme.palette.primary.main}`,
  },
  disabled: {
    opacity: 0.5,
  },
}));

type Props = {
  name: string;
  options: {
    id: number;
    image: string;
  }[];
  value?: number;
  onChange: (event: any) => void;
  error?: string;
  disabled?: boolean;
};

export default function ImageSelect({ name, options, value, onChange, error, disabled }: Props) {
  const { classes } = useStyles();

  const handleChange = (value: number) => !disabled && onChange({ target: { name, value } });

  return (
    <>
      <Typography className={classes.label}>Imagen*</Typography>
      <Grid container>
        {options.map((option) => (
          <Grid item xs={4} sm={3} md={2} key={option.id}>
            <Card
              className={`${classes.card} ${!disabled && classes.pointer} ${disabled && classes.disabled} ${
                option.id === value && classes.selectedCard
              }`}
              onClick={() => handleChange(option.id)}
            >
              <CardMedia className={classes.image} image={option.image}></CardMedia>
            </Card>
          </Grid>
        ))}
      </Grid>
      {!!error && (
        <FormHelperText variant='filled' error={true}>
          {error}
        </FormHelperText>
      )}
    </>
  );
}
