import { Box, Button, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { sendPasswordResetEmail } from 'firebase/auth';
import ErrorContext from 'contexts/errorContext';
import { FieldErrors, FieldsAPIError } from 'errors';

const useStyles = makeStyles()((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  link: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

export default function ResetPasswordScreen() {
  const { auth } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const { classes } = useStyles();
  const [email, setEmail] = useState('');
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
  const [success, setSuccess] = useState(false);

  const handleChange = (event: any) => {
    setEmail(event.target.value);
  };

  const submit = (event: any) => {
    event.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => setSuccess(true))
      .catch((err) => {
        if (err.code === 'auth/invalid-email') {
          setError({ message: 'Email inválido.' });
        } else if (err.code === 'auth/missing-email') {
          setError({ message: 'Ingresa tu email.' });
        } else if (err.code === 'auth/user-not-found') {
          setError({ message: 'Usuario no encontrado.' });
        } else if (err instanceof FieldsAPIError) setFieldErrors(err.fieldErrors);
        else setError(err);
      });
  };

  const getFieldError = (field: string) => fieldErrors && fieldErrors[field];

  return (
    <>
      {!!success ? (
        <>
          <Box className={classes.box}>
            <CheckCircleIcon className={classes.icon} fontSize='medium' />
            <Typography variant='h6'>¡Enviado!</Typography>
          </Box>
          <Box className={classes.box}>
            <Typography align='center'>Accede a tu email para cambiar tu contraseña.</Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography variant='h5' gutterBottom>
            Recupera tu cuenta
          </Typography>
          <Typography variant='subtitle2' align='center'>
            Ingresa tu email para que te enviemos un link de cambio de contraseña.
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              type='text'
              name='email'
              label='Email'
              margin='normal'
              onChange={handleChange}
              required
              fullWidth
              error={getFieldError('email') !== undefined}
              helperText={getFieldError('email')}
            />
            <Button className={classes.submit} type='submit' variant='contained' color='primary' fullWidth onClick={submit}>
              Enviar
            </Button>
          </form>
        </>
      )}
      <Link className={classes.link} to={'/auth'}>
        <Typography variant='subtitle2'>Volver</Typography>
      </Link>
    </>
  );
}
