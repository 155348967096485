import { Box, Link, Typography } from '@mui/material';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import StoreContext from 'contexts/storeContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { makeStyles } from 'tss-react/mui';
import { useContext } from 'react';

const useStyles = makeStyles()((theme) => ({
  container: { display: 'flex', marginBottom: theme.spacing(1) },
  icon: { marginTop: 3, marginRight: theme.spacing(1) },
}));

export default function HelpScreen() {
  const { classes } = useStyles();
  const { store } = useContext(StoreContext);

  return (
    <>
      <Box className={classes.container}>
        <ChatBubbleOutlineIcon fontSize='small' className={classes.icon} />
        <Typography variant='body2'>
          Si quieres <b>reportar un error</b>, <b>proponer una nueva funcionalidad</b>, o <b>dejarnos un comentario</b> sobre la plataforma,
          puedes hacerlo a través de{' '}
          <Link href='https://forms.gle/1A6gVzwC5cN1GizGA' target='_blank' underline='always'>
            <b>este formulario</b>
          </Link>
          .
        </Typography>
      </Box>
      <Box className={classes.container}>
        <WhatsAppIcon fontSize='small' className={classes.icon} />
        <Typography variant='body2'>
          Si por lo contrario <b>necesitas ayuda</b> con el uso de la misma, ponte en contacto con nosotros a través de{' '}
          <Link href={`https://wa.me/${store.country.whatsapp_support_number}`} target='_blank' underline='always'>
            <b>WhatsApp</b>
          </Link>
          .
        </Typography>
      </Box>
    </>
  );
}
