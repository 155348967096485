import { Tooltip } from '@mui/material';

type Props = {
  children: React.ReactNode;
  hasPermission: boolean;
};

// if hasPermission is false, shows a permissions message on hover
export default function PermissionTooltip({ children, hasPermission }: Props) {
  if (hasPermission) {
    return children;
  }
  return (
    <Tooltip title='No tienes permisos para realizar esta acción' placement='bottom' enterTouchDelay={0}>
      <span>{children}</span>
    </Tooltip>
  );
}
