import { Box, Button, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import ApiContext from 'contexts/apiContext';
import StoreContext from 'contexts/storeContext';
import { OpeningHours, OpeningHoursRequest, OpeningHoursRequestBeingCreated, OpeningHoursWithRequest } from 'types/openingHours';
import { OPENING_HOURS_REQUEST_ACTION, OPENING_HOURS_REQUEST_STATUS, WEEKDAYS } from 'consts';
import { Add } from '@mui/icons-material';
import OpeningHoursDialog from 'components/OpeningHoursDialog';
import OpeningHoursCard from 'components/OpeningHoursCard';
import Spinner from 'common/Spinner';
import RejectedOpeningHoursRequestsDialog from 'components/RejectedOpeningHoursDialog';
import ErrorContext from 'contexts/errorContext';

const filterandMergeRequestsToOpeningHours = (
  openingHours: OpeningHours[],
  requests: OpeningHoursRequest[],
  status: OPENING_HOURS_REQUEST_STATUS,
): OpeningHoursWithRequest[] => {
  const filteredOpeningHoursRequests = requests.filter((request) => request.status === status);
  const openingHoursWithPendingRequests: OpeningHoursWithRequest[] = [];
  for (const openingHour of openingHours) {
    openingHoursWithPendingRequests.push({
      ...openingHour,
      request: filteredOpeningHoursRequests.find((request) => request.target_opening_hour === openingHour.id),
    });
  }
  filteredOpeningHoursRequests
    .filter((request) => request.action === OPENING_HOURS_REQUEST_ACTION.CREATE)
    .forEach((request) => {
      request.weekday &&
        request.from_time &&
        request.to_time &&
        openingHoursWithPendingRequests.push({
          id: 0, // dummy id for type checking
          weekday: request.weekday,
          from_time: request.from_time,
          to_time: request.to_time,
          request,
        });
    });
  return openingHoursWithPendingRequests;
};

export default function OpeningHoursComponent() {
  const { store } = useContext(StoreContext);
  const { api } = useContext(ApiContext);
  const { setError } = useContext(ErrorContext);
  const [openingHoursRequests, setOpeningHoursRequests] = useState<OpeningHoursRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [rejectedDialogIsOpen, setRejectedDialogIsOpen] = useState(false);

  const mobile = useMediaQuery('(max-width: 600px)');

  const refreshOpeningHourRequests = useCallback(() => {
    api.openingHoursRequests
      .list_not_paginated(store.id)
      .then((res) => setOpeningHoursRequests(res.data))
      .catch(setError)
      .finally(() => setLoading(false));
  }, [api, setError, store]);

  useEffect(refreshOpeningHourRequests, [refreshOpeningHourRequests]);

  const openDialog = () => {
    setCreateDialogIsOpen(true);
  };

  const closeDialog = (success: boolean) => {
    setCreateDialogIsOpen(false);
    if (success) refreshOpeningHourRequests();
  };

  const openingHoursWithPendingRequests = filterandMergeRequestsToOpeningHours(
    store.opening_hours,
    openingHoursRequests,
    OPENING_HOURS_REQUEST_STATUS.PENDING,
  );
  const openingHoursWithRejectedRequests = filterandMergeRequestsToOpeningHours(
    store.opening_hours,
    openingHoursRequests,
    OPENING_HOURS_REQUEST_STATUS.REJECTED,
  );

  const weekdayTitle = (weekdayName: string) => {
    return (
      <TableCell>
        <Typography fontWeight='bold'>{weekdayName}</Typography>
      </TableCell>
    );
  };

  return (
    <Box marginTop={2}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Button variant='contained' color='primary' startIcon={<Add />} sx={{ mr: 2, mt: 1 }} onClick={openDialog}>
            Añadir horario
          </Button>
          <Button sx={{ mr: 2, mt: 1 }} onClick={() => setRejectedDialogIsOpen(true)}>
            Ver solicitudes rechazadas
          </Button>
          <Table sx={{ mt: 2 }}>
            <TableBody>
              {WEEKDAYS.map((weekday) => (
                <React.Fragment key={weekday.name}>
                  {mobile && <TableRow>{weekdayTitle(weekday.name)}</TableRow>}
                  <TableRow key={weekday.name}>
                    {!mobile && weekdayTitle(weekday.name)}
                    <TableCell width='100%' sx={{ py: 1 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {openingHoursWithPendingRequests
                          .filter((oh) => oh.weekday === weekday.value)
                          .map((oh) => (
                            <OpeningHoursCard
                              oh={oh}
                              refreshOpeningHourRequests={refreshOpeningHourRequests}
                              // need to use both ids else id of an ohr can be same as id of an oh
                              key={`${oh.id}${oh.request?.id}`}
                            />
                          ))}
                      </Box>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      <OpeningHoursDialog
        isOpen={createDialogIsOpen}
        onClose={() => closeDialog(false)}
        onSuccess={() => closeDialog(true)}
        callback={(request: OpeningHoursRequestBeingCreated) => api.openingHoursRequests.create(request, store.id)}
      />
      <RejectedOpeningHoursRequestsDialog
        isOpen={rejectedDialogIsOpen}
        close={() => setRejectedDialogIsOpen(false)}
        rejectedOpeningHoursRequests={openingHoursWithRejectedRequests}
      />
    </Box>
  );
}
