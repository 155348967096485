export class APIError extends Error {}

export type FieldErrors = {
  [key: string]: string;
};

export class FieldsAPIError extends APIError {
  fieldErrors: FieldErrors;

  constructor(fieldErrors: FieldErrors, message = '') {
    super(message);
    this.fieldErrors = fieldErrors;
  }
}

export class APIArrayError extends APIError {
  errors: object[];

  constructor(errors: object[], message = '') {
    super(message);
    this.errors = errors;
  }
}
